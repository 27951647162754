.main {
  width: 100vw;
  max-width: 1152px;
  height: 100vh;
  min-height: 50rem;
  max-height: 70rem;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.logoutNav {
  position: absolute;
  right: 0;
  display: flex;
  gap: 0.5rem;
  margin: 4rem 2rem 0;
  color: #909090;
  align-items: center;
}

.logoutNav em {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  background-image: url(../../assets/images/logout-2.svg);
}

.logoutNav.active em,
.logoutNav:hover em {
  background-image: url(../../assets/images/logout-red.svg);
}

.header {
  display: grid;
  place-items: center;
  padding: 2.5rem;
}

.header img {
  height: 88px;
}

.container {
  text-align: center;
  border-radius: 8px;
  border: 1px solid #d1d5db;
  min-height: 36rem;
}

.container h1 {
  font-size: 2rem;
  font-weight: 700;
  line-height: normal;
}

.container p {
  font-size: 20px;
  font-weight: 400;
  padding-top: 1rem;
}

.spinner {
  margin: 0 auto;
  width: 2rem;
  height: 2rem;
}
