.main {
  width: 100vw;
  max-width: 1152px;
  height: 100vh;
  min-height: 50rem;
  max-height: 70rem;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.logoutNav {
  position: absolute;
  right: 0;
  display: flex;
  gap: 0.5rem;
  margin: 4rem 2rem 0;
  color: #909090;
  align-items: center;
}

.logoutNav em {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  background-image: url(../../assets/images/logout-2.svg);
}

.logoutNav.active em,
.logoutNav:hover em {
  background-image: url(../../assets/images/logout-red.svg);
}

.header {
  display: grid;
  place-items: center;
  padding: 2.5rem;
}

.header img {
  height: 88px;
}

.form {
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1.5rem;
  flex: 1;
  margin-bottom: 4rem;
}

.stepPanel {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.welcomeContainer {
  text-align: center;
  border-radius: 8px;
  border: 1px solid #d1d5db;
  min-height: 22rem;
  padding: 5rem 0;
}

.welcomeContainer h1 {
  color: #2bbc91;
  font-size: 40px;
  font-weight: 700;
  line-height: normal;
}

.welcomeContainer p {
  color: #909090;
  font-size: 20px;
  font-weight: 400;
  line-height: 16px;
}
.textCenter {
  text-align: center;
}
.btn {
  border-radius: 12px;
  height: 3rem;
  width: 13rem;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px; /* 125% */
}

.btnLg {
  width: 20rem;
  font-weight: 700;
}

.btnPrimary {
  border: 0;
  background-color: #2bbc91;
  color: white;
  height: 3rem;
  border-radius: 12px;
}

.btnSecondary {
  border: 2px solid rgba(0, 0, 0, 0.12);
  background-color: white;
  color: black;
}

.btnSecondary:disabled {
  background: lightgrey;
  opacity: 0.25;
  border-color: white;
  cursor: not-allowed;
}

.btnPrimary:disabled {
  background-color: rgba(43, 188, 145, 0.5);
  cursor: not-allowed;
}

.btnRow {
  display: flex;
  justify-content: space-between;
}

.brandContainer {
  flex: 1;
  border-radius: 8px;
  border: 1px solid #d1d5db;
  max-height: 35rem;
  overflow: scroll;
}

.brandContainer h2 {
  font-size: 40px;
  font-weight: 700;
  line-height: normal;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.brandsList {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.branchesContainer,
.checkoutContainer {
  flex: 1;
  border-radius: 8px;
  border: 1px solid #d1d5db;
  padding: 2rem;
  max-height: 35rem;
  overflow: scroll;
}

.checkoutContainer h2 {
  color: #101820;
  font-size: 18px;
  font-weight: 700;
  line-height: 32px; /* 177.778% */
}

.paymentsContainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 1rem 0;
  gap: 0.5rem;
}

.receiptContainer {
  margin-top: 2rem;
}

.spinner {
  margin: 0 auto;
  width: 2rem;
  height: 2rem;
}
