.accordionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0;
  width: 100%;
  height: 4rem;
  padding: 1rem;
  border-bottom: 1px solid rgba(43, 188, 145, 0.25);
  background-color: white;
}

.brandInfo {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #292d32;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
}

.brandLogo {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: lightgray;
  overflow: hidden;
}

.brandLogo img {
  width: 40px;
  height: 40px;
}

.lightText {
  color: rgba(41, 45, 50, 0.35);
}
.accordionChevron {
  transition: transform 500ms cubic-bezier(0.165, 0.84, 0.44, 1);
}
.accordionHeaderActive .accordionChevron {
  transform: rotate(180deg);
}
.accordionChevron svg {
  width: 24px;
  height: 24px;
}
.accordionBody {
  margin-left: 4rem;
}
html[dir="rtl"] .accordionBody {
  margin-right: 4rem;
  margin-left: 0rem;
}

.accordionBody {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
}

.branchRow {
  height: 4rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(43, 188, 145, 0.25);
}

.branchRow:last-child {
  border-bottom: 0;
}

.iconBtn {
  background-color: white;
  border: 0;
}

.iconBtn svg {
  width: 1.25rem;
  height: 1.25rem;
}

.branchActions {
  display: flex;
  align-items: center;
  gap: 1rem;
}
