.container {
  position: fixed;
  top: 0;
  width: 100%;
  height: 5rem;
  color: white;
  font-size: 1rem;
  z-index: 99999;
  transform: translateY(-100%);
  display: grid;
  place-items: center;
}

.show {
  transition: transform 200ms ease-in-out;
  transform: translateY(0);
}
.hide {
  transition: transform 200ms ease-in-out;
  transform: translateY(-100%);
}

.offline {
  background: #ed1c24;
}

.online {
  background: #2bbc91;
}
