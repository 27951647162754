.modal {
  padding: 1rem 2rem;
}

.header {
  padding: 0 !important;
}

.grid {
  border: 0 !important;
  display: flex;
  gap: 1rem;
}

.body {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 40rem;
  overflow: scroll;
}

.input {
  background-color: white !important;
  color: black !important;
  width: 100%;
  font-size: 14px !important;
  line-height: normal !important;
  display: flex;
  justify-content: space-between;
  padding: 4px 1rem !important;
  border-radius: 12px !important;
  border: 1px solid rgba(43, 188, 145, 0.25);
  background: var(--white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(169, 208, 246, 0.25);
}

.dropdownMenu {
  max-height: 15rem;
  overflow: scroll;
}

.dropdownItem {
  padding: 0.5rem 1rem !important;
}

.inputGroup {
  flex: 1;
}

.textarea {
  border-radius: 12px !important;
  border: 1px solid rgba(43, 188, 145, 0.25);
  background: var(--white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(169, 208, 246, 0.25);
  min-height: auto !important;
}

.iconBtn {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 13px 12px !important;
  border: 0;
  background: var(--white, #fff);
  color: #2bbc91;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.iconBtn svg {
  font-size: large;
}

.tableHeader {
  display: grid;
  grid-template-columns: 2rem auto auto 3rem;
  text-align: center;
  border-radius: 12px;
  border: 1px solid #2bbc91;
  font-size: 14px;
  padding: 6px 4px;
  color: #292d32;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
}

.tableRow {
  display: grid;
  grid-template-columns: 2rem auto auto 3rem;
  text-align: center;
  border-bottom: 1px solid rgba(43, 188, 145, 0.25);
  font-size: 14px;
  padding: 12px 4px;
  color: #292d32;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  gap: 1.5rem;
  align-items: center;
}

.footer {
  display: flex;
  justify-content: center;
  gap: 2rem;
}
.spinner {
  margin: 0;
  width: 20px;
  height: 20px;
}

.lgSpinner {
  margin: 2rem auto;
  width: 4rem;
  height: 4rem;
}
