.container {
  width: 100%;
  color: white;
  font-size: 1rem;
  transition: height 200ms ease-in-out;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-color: #ed1c24;
}

/* I'm sorry, had to do it */
@media (max-width: 1099px) {
  .container {
    margin-top: 4rem;
  }
}
.show {
  height: 4rem;
}
.hide {
  height: 0rem;
}

.btn {
  min-width: 0;
  padding: 0.5rem;
  border-radius: 100%;
  background-color: transparent !important;
  border: 0;
}
.btn:hover {
  background-color: rgba(128, 128, 128, 0.2) !important;
}

.link {
  color: white;
  text-decoration: underline;
}
