.cardContainer {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border: 1px solid rgba(43, 188, 145, 0.25);
  border-radius: 12px;
  background: white;
  width: 14rem;
  gap: 2rem;
}

.cardContainerDefault {
  border: 1px solid #2bbc91 !important;
}

.cardContainer > div {
  display: flex;
  justify-content: space-between;
}

.cardTitle {
  color: rgba(41, 45, 50, 0.5);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.cardContainer button {
  border: 0;
  padding: 0;
  min-width: 0;
  background-color: white;
}
