ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.brandAccordion.accordion>.card {
  border: 0;
  border-bottom: 1px solid var(--bdrColor);
  background: transparent;
  border-radius: 0;
  margin: 0;
}

.brandAccordion.accordion>.card.active {
  border: 1px solid var(--bdrColor);
  border-radius: var(--Radius12);
  margin: 5px 0px 5px 0px;
}

.brandAccordion.accordion>.card>.card-header {
  padding: 1rem;
  margin: 0;
  border: 0;
  background: transparent;
}

.brandAccordion.accordion>.card>.card-header .btn {
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;
  padding: 0;
  width: 100%;
}

.brandTitle {
  display: flex;
  align-items: center;
  margin: 0 1rem 0 0;
}

.brandTitle span {
  margin: 0 12px 0 0;
}

.brandTitle li span img,
.brandTable .draggable img {
  width: 30px;
  height: 24px;
}

.brandTitle strong {
  color: var(--darkColor);
  font-size: var(--font16);
  font-weight: var(--weight600);
}

.reviewDisplay {
  display: flex;
  align-items: center;
  background: var(--lightYellow);
  width: 55px;
  height: 24px;
  border-radius: var(--Radius4);
  padding: 6px;
  justify-content: center;
  background: rgba(255, 214, 0, 0.15);
}

.reviewDisplay span {
  font-size: var(--font16);
  color: var(--darkColor);
  line-height: 14px;
  font-weight: var(--weight600);
  margin: 0;
}

.reviewDisplay strong {
  line-height: 14px;
  margin-left: 0.25rem;
}

.reviewDisplay strong img {
  height: 14px;
  width: 14px;
  vertical-align: top;
}

.brandIcons {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.brandIcons span {
  margin-left: 28px;
}

.brandIcons span img {
  width: 22px;
  height: 100%;
}

.inputWrap {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
}

.inputWrap label {
  font-size: var(--font16);
  color: var(--darkColor);
  font-weight: var(--weight500);
  line-height: 20px;
  margin: 0 0 8px;
  display: block;
}

.inputWrap .container {
  padding: 0;
}

.plainBTN {
  background: transparent;
  border: 0;
}

.dropdown-item:active {
  background: var(--primary);
  border-radius: 0px;
}

.dropdown-menu>span:last-child:active {
  border-radius: 0 0 12px 12px;
}

.brandInfo {
  border: 1px solid var(--bdrColor);
  border-radius: var(--Radius12);
  padding: 5px 0;
  box-shadow: 0px 1px 2px rgba(169, 208, 246, 0.25);
  margin: 0 0 1.5rem;
}

.dropdown-item {
  border-bottom: 1px solid var(--bdrColor);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  // padding: 15px 0 15px 15px !important;
}

.brandInfo ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
}

.brandInfo ul li {
  width: 16.66%;
  text-align: center;
}

.brandInfo ul li span {
  display: block;
  color: var(--greyColor);
  font-size: var(--font14);
  line-height: 26px;
  font-weight: var(--weight500);
}

.brandInfo ul li strong {
  display: block;
  color: var(--darkColor);
  font-size: var(--font14);
  line-height: 26px;
  font-weight: var(--weight700);
}

.brandSearch {
  display: flex;
}

.brandSearch .inputWrap {
  max-width: 570px;
  width: 100%;
}

.brandFilter {
  margin: 0 0 0 auto;
  max-width: 150px;
  width: 100%;
}

.brandFilter button span {
  margin-right: 10px;
}

.branchTitle p {
  margin: 0;
  padding: 0;
  font-size: var(--font14);
  font-weight: var(--weight400);
  color: var(--darkColor);
  line-height: 26px;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 26px !important;
  white-space: nowrap;
  width: 99%;
  display: block;
}

.branchTitle span {
  margin: 0;
  padding: 0;
  font-size: var(--font14);
  font-weight: var(--weight400);
  color: var(--greyColor);
  line-height: 26px;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 26px !important;
  white-space: nowrap;
  width: 99%;
  display: block;
}

.branchTitle {
  text-align: left;
  padding-left: 10px;
  max-width: 150px;
}

.brandTable {
  margin: 0 0 20px;
  // height: 300px;
  // overflow-y: scroll;
}

.brandTable::-webkit-scrollbar {
  height: 8px;
}

.brandTable::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px #d2e3f3; */
  border-radius: 10px;
}

.brandTable .table td {
  padding: 6px;
  border-bottom: 1px solid var(--bdrColor);
}

.addBranch button,
.addBranch a {
  display: inline-block;
  vertical-align: top;
}

.addBranch button span img {
  width: 20px;
  height: 100%;
}

.addBranch button strong,
.addBranch a strong {
  margin: 0 0 0 10px;
  font-size: var(--font16);
  color: var(--primary);
  font-weight: var(--weight700);
}

.addBranch button:hover strong,
.addBranch a:hover strong {
  text-decoration: none;
}

.brandAccordion {
  margin: 0 0 10px;
  display: inline-block;
  width: 100%;
  vertical-align: top;
}

.modal-content {
  border: 0;
  border-radius: var(--Radius12);
  padding: 32px;
}

.modal-header {
  border: 0;
  padding: 0 0 32px;
}

.modal-body {
  padding: 0;
}

.centerBTN {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.centerBTN button {
  min-width: 120px;
  margin: 0 9px;
}

.modalAddBranch .modal-dialog {
  max-width: 822px;
  width: 100%;
}

.modalAddBranch .modal-body {
  display: flex;
  flex-wrap: wrap;
}

.modalAddBranchLeft {
  width: 100%;
  max-width: 372px;
}

.modalAddBranchLeft .customSelect .bootstrap-select .dropdown-toggle {
  padding: 7px 12px;
}

.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select>select.mobile-device:focus+.dropdown-toggle {
  outline: none !important;
  outline-offset: 0;
  box-shadow: none;
}

.modalAddBranchRight {
  margin-left: auto;
  max-width: calc(100% - 392px);
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.experience-wpr-content .container,
.modalAddBranchRight .container {
  padding: 0;
  max-width: 100%;
}

.dropzone.ms-4 {
  margin: 0 !important;
}

#top-nav ul {
  list-style: none;
}

#top-nav ul li a:hover {
  border-radius: 12px;
}

ul.dropdown-menu>li>a {
  text-align: left !important;
}

ul.dropdown-menu>li {
  padding: 8px 16px;
  border-bottom: 1px solid var(--bdrColor);
  border-radius: 12px 12px 0px 0px;
  display: block !important;
  white-space: nowrap;
}

li.dropdown.opened {
  width: 150px;
}

li.dropdown {
  position: relative;
}

ul.dropdown-menu {
  position: absolute;
  min-width: 250px;
  border-bottom: 1px solid var(--bdrColor);
  left: -265px;
  top: 10px;
  padding-bottom: 16px;
}

ul.dropdown-menu ul.dropdown-menu {
  left: 100%;
  top: 0;
}

.drop_box {
  cursor: pointer;
  height: 210px;
  width: 100%;
  border: 2px dashed var(--bdrColor);
  border-radius: var(--Radius12);
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.drop_box img {
  margin: 0 0 12px;
}

.drop_box button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border: 0;
  font-size: 0;
}

.drop_box h4 {
  margin: 0 0 4px;
  padding: 0;
  display: block;
  font-size: var(--font14);
  font-weight: var(--weight500);
  color: var(--primary);
  line-height: 20px;
}

.drop_box h4 span {
  color: var(--darkColor);
}

.drop_box p {
  margin: 0;
  padding: 0;
  display: block;
  font-size: var(--font12);
  font-weight: var(--weight500);
  color: var(--greyColor);
  line-height: 20px;
}

.drop_box input {
  border: 0;
  box-shadow: none;
  padding: 0;
  text-align: center;
}

.noBrands {
  position: absolute;
  width: 231px;
  height: 309px;
  left: 50%;
  top: 25%;
  text-align: center;
}

.brands-text {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 45px;
  display: flex;
  align-items: center;
  color: #909090;
}

.brandsButton {
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  background: var(--primary);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
}

.thumb {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin: 8px auto;
  width: 100%;
  height: 160px;
  padding: 4px;
  box-sizing: border-box;
}

.thumbInner {
  display: flex;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
}

.thumbImg {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.brandImg {
  width: 40px;
  height: 40px;
}

.brandImg img {
  width: 100%;
  height: 100%;
  // object-fit: contain;
  // border-radius: 50%;
}

.brandImg .sb-avatar .sb-avatar__text span {
  margin: 0;
}

.brand-wpr {
  width: 100%;
  justify-content: space-between;
  padding-bottom: 32px;
}

.tab_cstm_wrapper {
  width: calc(100% - 315px);
  justify-content: space-between;
  align-items: center;
}

.tab_panel_new {
  border: 1px solid var(--primary);
  border-radius: 12px;
  justify-content: space-between;
  align-items: center;
}

.tab_panel_new>li {
  border-radius: 12px;
  display: flex;
  align-items: center;
  font-family: "Cairo";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #292d32;
}

.tabs .active {
  background-color: var(--primary);
  color: #fff;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.experience-wpr {
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0 auto;
  margin-top: 77px;
}

.experience-inner>p {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 45px;
  color: #909090;
  text-align: center;
  padding-top: 24px;
}

.experience-inner>img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.exper-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  padding: 9px 17px;
  height: 48px;
  margin: 0 auto;
}

.edit-data.experience-btn {
  margin: 0;
  width: 250px;
  height: 48px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  border: 0;
  background: var(--primary);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  color: #fff;
  font-family: "Cairo";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.nav-tabs .nav-link.active {
  border: 0;
  background-color: var(--primary);
  color: #fff;
  border-radius: 12px;
}

.nav-link {
  color: black;
}

.experience-wpr-content {
  margin-top: 32px;
}

.experience-inner-content {
  max-width: 100%;
  width: 100%;
  padding: 0px 10px;
}

.exper-ul {
  width: 100%;
}

.exper-li {
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--bdrColor);
  padding: 9px 15px;
}

.experience-inner-content {
  max-width: 100%;
  width: 100%;
  padding: 0px 10px;
}

.exper-name>h6 {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #292d32;
  margin: 0;
}

.exper-dropdown {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(169, 208, 246, 0.25);
  border-radius: 12px;
  max-width: 180px;
}

.edit-exper {
  position: relative;
}

ul.exper-drop-ul {
  width: 180px;
  position: absolute;
  background: #ffffff;
  border: 1px solid var(--bdrColor);
  box-shadow: 0px 1px 2px rgba(169, 208, 246, 0.25);
  border-radius: 12px;
  left: 0px;
  top: 30px;
  z-index: 99;
}

ul.exper-drop-ul>li {
  padding: 10px 16px;
  border-bottom: 1px solid var(--bdrColor);
}

// .dropdown-menu ul li+li {border-top: 1px solid var(--bdrColor);}
.dropdown-menu ul li:first-child a {
  border-radius: 12px 12px 0 0;
}

.dropdown-menu ul li:last-child a {
  border-radius: 0 0 12px 12px;
}

.dropdown-menu ul li a {
  border-radius: 0;
}

.exper-drop-ul>li>a {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #000000;
}

.exper-drop-ul>li>a>span {
  margin-right: 5px !important;
}

ul.exper-drop-ul>li:last-child {
  border: none;
}

.exper-drop-ul>li>.delet-color {
  color: #ed1c24;
}

.modal-fade-padding-0 {
  padding-right: 0 !important;
}

.upArrow {
  transform: rotate(180deg);
}

.modal-dialog.modal-wpr {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  border-radius: 12px;
}

.add-new-user {
  text-align: left;
  width: 100%;
  padding: 20px 0px;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: #212529;
  background: #ffffffde;
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.nav-tabs .nav-link.active:hover {
  color: white !important;
}

a.user-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: "Cairo";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--primary);
  cursor: pointer;
}

span.user-icon {
  margin-right: 8px;
  color: #fff;
}

.staff-img {
  width: 35px;
  height: 35px;
}

.page-link {
  color: var(--primary) !important;
}

.active>.page-link {
  color: white !important;
}

.staff-img img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
}

.dropzone.ms-4 {
  margin: 0 !important;
}

#top-nav ul {
  list-style: none;
}

#top-nav ul li a:hover {
  border-radius: 12px;
}

ul.dropdown-menu>li>a {
  text-align: left !important;
}

ul.dropdown-menu>li {
  padding: 8px 16px;
  border-bottom: 1px solid var(--bdrColor);
  border-radius: 12px 12px 0px 0px;
  display: block !important;
  white-space: nowrap;
}

li.dropdown.opened {
  width: 150px;
}

li.dropdown {
  position: relative;
}

ul.dropdown-menu {
  position: absolute;
  min-width: 250px;
  border-bottom: 1px solid var(--bdrColor);
  left: -265px;
  top: 10px;
  padding-bottom: 16px;
}

ul.dropdown-menu ul.dropdown-menu {
  left: 100%;
  top: 0;
}

.drop_box {
  cursor: pointer;
  height: 210px;
  width: 100%;
  border: 2px dashed var(--bdrColor);
  border-radius: var(--Radius12);
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.drop_box img {
  margin: 0 0 12px;
}

.drop_box button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border: 0;
  font-size: 0;
}

.drop_box h4 {
  margin: 0 0 4px;
  padding: 0;
  display: block;
  font-size: var(--font14);
  font-weight: var(--weight500);
  color: var(--primary);
  line-height: 20px;
}

.drop_box h4 span {
  color: var(--darkColor);
}

.drop_box p {
  margin: 0;
  padding: 0;
  display: block;
  font-size: var(--font12);
  font-weight: var(--weight500);
  color: var(--greyColor);
  line-height: 20px;
}

.drop_box input {
  border: 0;
  box-shadow: none;
  padding: 0;
  text-align: center;
}

.noBrands {
  position: absolute;
  width: 231px;
  height: 309px;
  left: 50%;
  top: 25%;
  text-align: center;
}

.brands-text {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 45px;
  display: flex;
  align-items: center;
  color: #909090;
}

.brandsButton {
  margin: 0 auto;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  background: var(--primary);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
}

.thumb {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin: 8px auto;
  width: 100%;
  height: 160px;
  padding: 4px;
  box-sizing: border-box;
}

.thumbInner {
  display: flex;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
}

.thumbImg {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.brandImg {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
}

.brandImg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.brand-wpr {
  width: 100%;
  justify-content: space-between;
  padding-bottom: 32px;
}

.nav-item {
  width: 20%;
  text-align: center;
  font-family: "Cairo";
  font-style: normal;
  font-size: 15px;
  white-space: nowrap;
  line-height: 1.2;
}

.tab_cstm_wrapper {
  width: calc(100% - 315px);
  justify-content: space-between;
  align-items: center;
}

.tab_panel_new {
  border: 1px solid var(--primary);
  border-radius: 12px;
  justify-content: space-between;
  align-items: center;
}

// .brand-mobile-phone img {
//     max-width: 100%;
// }

.tab_panel_new>li {
  border-radius: 12px;
  display: flex;
  align-items: center;
  font-family: "Cairo";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 30px;
  color: #292d32;
  // width: calc(100% / 6);
  width: 16.66%;
}

.tabs .active {
  background-color: var(--primary);
  color: #fff;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.experience-wpr {
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0 auto;
  margin-top: 77px;
}

.experience-inner>p {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 45px;
  color: #909090;
  text-align: center;
  padding-top: 24px;
}

.experience-inner>img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.exper-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  padding: 9px 17px;
  height: 48px;
  margin: 0 auto;
}

.edit-data.experience-btn {
  margin: 0;
  width: 250px;
  height: 48px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  border: 0;
  background: var(--primary);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  color: #fff;
  font-family: "Cairo";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.nav-tabs .nav-link.active {
  border: 0;
  background-color: var(--primary);
  color: #fff;
  border-radius: 12px;
}

.nav-link {
  color: black;
}

.experience-wpr-content {
  margin-top: 32px;
}

.experience-inner-content {
  max-width: 100%;
  width: 100%;
  padding: 0px 10px;
}

.exper-ul {
  width: 100%;
}

.exper-li {
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--bdrColor);
  padding: 9px 15px;
}

.experience-inner-content {
  max-width: 100%;
  width: 100%;
  padding: 0px 10px;
}

.exper-name>h6 {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #292d32;
  margin: 0;
}

.exper-dropdown {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(169, 208, 246, 0.25);
  border-radius: 12px;
  max-width: 180px;
}

.edit-exper {
  position: relative;
}

ul.exper-drop-ul {
  width: 180px;
  position: absolute;
  background: #ffffff;
  border: 1px solid var(--bdrColor);
  box-shadow: 0px 1px 2px rgba(169, 208, 246, 0.25);
  border-radius: 12px;
  left: 0px;
  top: 30px;
  z-index: 99;
}

ul.exper-drop-ul>li {
  padding: 10px 16px;
  border-bottom: 1px solid var(--bdrColor);
}

// .exper-drop-ul-li {border-bottom: 1px solid var(--bdrColor);}
.exper-drop-ul-li a:hover {
  border-radius: 0;
}

.exper-drop-ul-li:last-child {
  border-bottom: 0px;
}

.exper-drop-ul>li>a {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #000000;
}

.exper-drop-ul>li>a>span {
  margin-right: 5px !important;
}

ul.exper-drop-ul>li:last-child {
  border: none;
}

.exper-drop-ul>li>.delet-color {
  color: #ed1c24;
}

.modal-fade-padding-0 {
  padding-right: 0 !important;
}

.upArrow {
  transform: rotate(180deg);
}

.modal-dialog.modal-wpr {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  border-radius: 12px;
}

.add-new-user {
  text-align: left;
  width: 100%;
  padding: 20px 0px;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-btn-color);
  background: #ffffffde;
  border-radius: 12px 12px 0 0px;
}

.nav-tabs .nav-link.active:hover {
  color: white !important;
}

a.user-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: "Cairo";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--primary);
  cursor: pointer;
}

span.user-icon {
  margin-right: 8px;
  color: #fff;
}

.staff-img {
  width: 35px;
  height: 35px;
}

.page-link {
  color: var(--primary) !important;
}

.active>.page-link {
  color: white !important;
}

.staff-img img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
}

.exp-modal-btn:hover {
  background: #56e2b8 !important;
  opacity: 1;
}

.exp-modal-btn:active {
  background: #56e2b8 !important;
  opacity: 1;
}

.Cancel-passwor:hover,
.Cancel-passwor:active {
  border: 1px solid var(--bdrColor) !important;
  opacity: 1 !important;
  color: #292d32 !important;
  background-color: rgba(41, 45, 50, 0.1) !important;
}

.cust-title {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: #292d32;
  border-bottom: none;
}

.exp-modal-body {
  padding: 0;
}

.Experience-btn-modal {
  margin-top: 48px;
}

.Experience-btn-modal button {
  margin: 0 0.5rem;
}

.contentHead .customSelect {
  min-width: 295px;
}

.social-buuton {
  text-align: center;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-buuton button {
  width: 250px;
}

.customSelect .dropdown-toggle {
  padding: 11px 16px 11px 10px;
  border: 1px solid var(--bdrColor);
  border-radius: var(--Radius12);
  box-shadow: var(--boxShadow);
}

.btn-content {
  background: var(--primary);
  opacity: 1;
  padding: 15px 45px;
}

.Cancel-passwor {
  background: #ffffff;
  border: 1px solid var(--bdrColor);
  box-shadow: 0px 1px 2px rgba(169, 208, 246, 0.25);
  border-radius: 12px;
  padding: 14px 40px;
  margin: 0px 9px;
  color: #292d32;
}

.Cancel-passwor:hover {
  border: 1px solid var(--bdrColor);
  opacity: 1;
  color: #292d32;
}

.staff-wrp {
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 2rem 0px 1rem 0px;
}

.branches-inner {
  max-width: 390px;
  width: 100%;
}

.search-inner {
  position: absolute;
  top: 8px;
  left: 16px;
  bottom: 8px;
  margin: 0;
}

.filter-inner {
  width: 150px;
  display: flex;
  align-items: center;
}

.filter-btn {
  border: 1px solid var(--primary);
  filter: drop-shadow(0px 1px 2px rgba(63, 90, 169, 0.15));
  border-radius: 12px;
  padding: 8px 20px;
  font-family: "Cairo";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--primary);
  display: flex;
  align-items: center;
}

.filter-btn>span {
  margin-right: 8px;
}

.filter-container {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: left !important;
  font-family: "Cairo";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.filter-container .checke-filter {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid var(--bdrColor);
  border-radius: 4px;
}

.filter-container .checke-filter:checked~.checkmark {
  background: var(--primary);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.filter-container .checke-filter:checked~.checkmark:after {
  display: block;
}

.filter-container .checkmark:after {
  left: 6px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

ul.submenu-filter {
  margin: 10px 0px 20px 0px;
}

ul.submenu-filter>li {
  padding: 8px 0px;
  border-bottom: 1px solid var(--bdrColor);
  border-radius: 12px 12px 0px 0px;
  text-align: left !important;
}

ul.submenu-filter>li>a {
  color: #000000;
}

.apply-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  width: 180px;
  height: 40px;
  margin: 0 auto;
  background: var(--primary);
  margin-top: 16px;
  box-shadow: 0px 1px 2px rgb(169, 208, 246 / 25%);
  border-radius: 12px;
  cursor: pointer;
}

#top-nav ul li {
  display: block;
  width: 100%;
  text-align: center;
}

.apply-filter>a {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff !important;
}

.stab-table {
  margin: 0 0 1.25rem;
  width: 100%;
}

.star-ratting-4 {
  width: 85px;
  height: 18px;
  display: block;
  margin: 5px auto;
}

.center {
  justify-content: center;
  align-items: center;
}

.star-white-4 {
  width: 85px;
  height: 18px;
  display: block;
  margin: 0 auto;
}

.b-bottom-1px {
  border-bottom: 1px solid #d9d9d9 !important;
}

.flex.settlemen {
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.settlement-24 {
  width: 24px;
  height: 24px;
  background: rgba(43, 188, 145, 0.15);
  border-radius: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  font-family: "Cairo";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  cursor: pointer;
}

.select-right-icon .bootstrap-select>.dropdown-toggle:after {
  border: 0;
  min-width: 24px;
  height: 24px;
  display: block;
  margin: 0;
}

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%;
}

.Customer,
.lg-Customer {
  margin-top: 24px;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.save-btn-change {
  width: 251px;
  height: 48px;
  box-shadow: 0px 1px 2px rgba(169, 208, 246, 0.25);
}

.customSelect.staticIcon.select-right-icon button.btn:before {
  content: "";
  display: block;
  position: absolute;
  top: 11px;
  left: 16px;
}

.select-right-icon .filter-option {
  padding-right: 35px;
}

.default-color {
  position: relative;
}

.default-color::after {
  right: 0;
  top: 0;
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background: rgba(160, 85, 10, 0.1);
  border-radius: 6px;
}

.customSelect.staticIcon.select-Branchs button.btn:before {
  content: "";
  background: none;
  display: block;
  position: absolute;
}

.select-right-icon .filter-option {
  padding-left: 25px;
}

.select-Branchs .filter-option {
  padding-left: 0px;
}

.default-color {
  color: #909090;
}

.select-right-icon .dropdown-toggle,
.select-Branchs .dropdown-toggle {
  padding: 6px 12px;
}

.dropdown-menu.show {
  margin-top: 15px;
  cursor: pointer;
  // min-width: 230px;
}

.dropdown-menu>span:last-child {
  border-bottom: 0px;
}

.modal-uploaded-section {
  padding: 32px 32px 137px 32px;
  border: 0;
  width: 100%;
  margin: 0 auto;
  max-width: 822px;
}

.outer-modal-option {
  width: 100%;
}

.nav-tabs .nav-link:hover {
  color: var(--primary) !important;
}

.staff-member-inner {
  width: 55%;
}

.modal-picture-uploaded {
  width: 100%;
}

.modal-picture-uploaded h6 {
  margin-bottom: 10px;
}

.upload-picture {
  width: 100%;
  border-radius: 6px;
}

.pictures {
  width: 100%;
  border-radius: 10px;
  min-height: 194px;
}

.pictures img {
  border-radius: 12px 12px 0px 0px;
  height: 194px;
  width: 184px;
  object-fit: cover;
}

.prive {
  width: 184px;
  background: rgba(41, 45, 50, 0.75);
  border-radius: 0px 0px 12px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ul-uploaded>li {
  padding: 4px 10px;
}

.ratings-form {
  margin: 35px auto;
}

.ratings-content {
  width: 60%;
}

.ratings-span {
  font-size: 11px;
  margin-top: 5px;
  color: grey;
}

.caret {
  display: none !important;
}

.check-radio-btn {
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.toggle-next {
  border-radius: 0;
}

.ellipsis button {
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  background: var(--white);
  border: 1px solid var(--bdrColor);
  border-radius: var(--Radius12);
  justify-content: flex-start;
  padding: 13px 12px;
}

.ellipsisDrop button {
  color: #333;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  background: var(--white);
  border: 1px solid var(--bdrColor);
  border-radius: var(--Radius12);
  justify-content: flex-start;
  padding: 13px 12px;
}

.ellipsisDrop button:focus {
  background: transparent;
  color: #333;
}

.dropdown-menu span:last-child {
  border-radius: 0 0 12px 12px;
}

.dropdown-menu span:first-child {
  border-radius: 12px 12px 0 0;
}

.ellipsis button:hover,
.ellipsis button:active {
  background-color: transparent !important;
  border-color: var(--primary) !important;
}

.ellipsisDrop:hover button,
.ellipsisDrop button:active {
  background-color: transparent !important;
  color: #333 !important;
}

.ellipsisDrop .dropdown-menu a {
  padding: 10px;
  border: 0;
}

.ellipsisDrop .dropdown-menu a+a {
  border-top: 1px solid var(--bdrColor);
}

.dropdown-menu {
  width: 100%;
}

.apply-selection {
  display: none;
  width: 100%;
  margin: 0;
  padding: 5px 10px;
}

.ajax-link {
  display: none;
}

button:focus {
  border-color: inherit;
}

button.btn.is-invalid {
  border: 1px solid var(--bdrColor);
}

button.btn.is-invalid:hover,
button.btn.is-invalid {
  background-image: url("../../assets//images/errorIcon.svg");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  border-color: #dc3545 !important;
}

.ellipsisDrop button {
  padding: 11px 12px;
}

.close_btn {
  width: 30px;
  height: 30px;
}

button.close_btn:focus,
button.close_btn:hover {
  color: var(--primary);
}

.checkboxes {
  margin: 0;
  border-top: 0;
}

.inner-wrap {
  overflow: auto;
  background: #ffffff;
  border: 1px solid var(--bdrColor);
  border-radius: 12px;
  margin-top: 4px;
  z-index: 999;
  overflow: inherit;
}

.radio-and-check {
  justify-content: space-between;
  align-items: center;
  // border-bottom: 1px solid var(--bdrColor);
  // padding: 12px 0px;
  // padding: 5px 0px;
}

.radio-and-check>div {
  display: flex;
  align-items: center;
}

.radio-and-check>div>.radio-defalut {
  width: auto;
  line-height: 16px;
}

.radio-defalut {
  margin-right: 6px;
  color: #909090;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
}

.completeSpan {
  color: #2bbc91 !important;
  cursor: pointer;
}

.inCompleteSpan {
  color: #ed1c24 !important;
  cursor: pointer;
}

.multipul-languages {
  position: relative;
}

.multipul-languages .d-Arrow {
  position: absolute;
  right: 11px;
}

.dropdown .btn-primary:focus {
  background-color: transparent;
}

// .react-select__menu {
//     color: #292D32 !important;
//     background-color: red !important;

// }

// .radio-and-check:last-child {
//     border-bottom: 0;
// }

.dropdown-menu>span:last-child .radio-and-check {
  border-bottom: 0 !important;
}

.input-radio {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.lg-fonts {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding-left: 6px;
}

.lg-label {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* START TOOLTIP STYLES */
[tooltip] {
  position: relative;
  z-index: 999;
}

/* Applies to all tooltips */
[tooltip]::before,
[tooltip]::after {
  text-transform: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}

[tooltip]::before {
  content: "";
  border: 5px solid transparent;
  z-index: 1001;
}

[tooltip]::after {
  content: attr(tooltip);
  font-family: Helvetica, sans-serif;
  text-align: center;
  width: 361px;
  min-height: 87px;
  text-overflow: ellipsis;
  padding: 23px 15px;
  background: #333;
  border-radius: 12px;
  color: #fff;
  line-height: 20px;
  z-index: 1000;
}

[tooltip]:hover::before,
[tooltip]:hover::after {
  display: block;
}

[tooltip=""]::before,
[tooltip=""]::after {
  display: none !important;
}

/* FLOW: UP */
[tooltip]:not([flow])::before,
[tooltip][flow^="up"]::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: #333;
}

[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::after {
  bottom: calc(100% + 5px);
}

[tooltip]:not([flow])::before,
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::before,
[tooltip][flow^="up"]::after {
  left: 50%;
  transform: translate(-50%, -0.5em);
}

/* FLOW: DOWN */
[tooltip][flow^="down"]::before {
  top: 100%;
  border-top-width: 0;
  border-bottom-color: #333;
}

[tooltip][flow^="down"]::after {
  top: calc(100% + 5px);
}

[tooltip][flow^="down"]::before,
[tooltip][flow^="down"]::after {
  left: 50%;
  transform: translate(-50%, 0.5em);
}

/* KEYFRAMES */
@keyframes tooltips-vert {
  to {
    opacity: 0.9;
    transform: translate(-50%, 0);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: 0.9;
    transform: translate(0, -50%);
  }
}

/* FX All The Things */
[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^="up"]:hover::before,
[tooltip][flow^="up"]:hover::after,
[tooltip][flow^="down"]:hover::before,
[tooltip][flow^="down"]:hover::after {
  animation: tooltips-vert 300ms ease-out forwards;
}

.custom-checkbox {
  display: inline-flex;
  align-items: center;
}

.user-dropdown button {
  padding: 0;
  background-color: transparent;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: var(--primary);
  background-color: var(--primary);
}

.lag-text {
  color: #000000 !important;
}

.color-text-picker {
  position: relative;
  color: white;
}

.color-logo-background-picker {
  position: relative;
  color: white;
}

.c_white {
  color: white !important;
}

.c_black {
  color: black !important;
}

.color-text-picker-white {
  position: relative;
  color: black;
}

.color-text-picker::after {
  content: "Buttons Color";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.color-logo-background-picker::after {
  content: "Sticker Logo Background Color";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.color-text-picker-white::after {
  content: "Buttons Text Color";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.checked-radio {
  display: block !important;
  opacity: 1 !important;
}

.Edit-Button-content .modal-content {
  padding: 32px;
  border: 0;
  border-radius: 12px;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -0.1rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid var(--bdrColor);
  border-radius: 4px;
}

.custom-control-label:focus {
  border: 0;
}

.custom-checkbox .custom-control-label::before {
  top: 0px;
}

.custom-control-label::after {
  top: 0;
}

/* =======lg-slider-toggle====== */
.lg-slider-toggle {
  margin-top: 24px;
}

.button-color {
  width: 100%;
  height: 48px;
}

.button-color input::-webkit-color-swatch {
  border-radius: 12px;
}

.button-color input::-webkit-color-swatch-wrapper {
  border-radius: 12px;
}

.color-btn {
  width: 100%;
  font-size: 18px;
}

.form-control .dropdown-value+span {
  display: flex;
  align-items: center;
}

.red-btn-color {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  font-weight: 500;
  font-size: 18px;
  width: 100%;
  border: none;
  height: 100%;
}

.form-control-color {
  height: 48px;
  border-radius: 12px;
}

.relative-color {
  position: relative;
  z-index: 999;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  top: 2px;
  font-weight: 500;
  font-size: 18px;
  line-height: 34px;
  width: 100%;
}

.buttons-Text {
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  color: #292d32;
  transition: all 0.3s;
}

.edit-button {
  color: var(--primary);
  border: 1px solid var(--primary);
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25));
  border-radius: 12px;
  background: #ffffff;
  transition: all 0.3s;
}

.d-flex .form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0px;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0px;
}

.d-flex label.form-check-label {
  padding-left: 5px;
}

input[type="checkbox"]:focus,
input[type="radio"]:focus {
  box-shadow: none;
}

.d-flex .form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0px;
}

.form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}

.Valid-from {
  margin-top: 24px;
}

.button-text-color {
  background: #ffffff;
  box-shadow: 0px 0px 2px rgb(0, 0, 0 / 25%);
  border-radius: 12px;
  color: #292d32;
  border: 0.5px solid #ebebeb;
}

.Edit-Buttons-Text,
.Edit-Buttons-Text:hover,
.Edit-Buttons-Text:active,
.Edit-Buttons-Text:focus-visible {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border: none;
  color: var(--primary) !important;
  background: #fff !important;
}

.save-btn-change:hover {
  color: #fff;
  cursor: pointer;
}

.brand-modal {
  width: 333px;
  background: white;
  position: absolute;
  z-index: 10;
  top: 0%;
  right: 20%;
  border: 1px solid var(--bdrColor);
  box-shadow: 0px 1px 2px rgba(169, 208, 246, 0.25);
  border-radius: 12px;
  z-index: 999;
}

.brand-filter-menu {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.star-container {
  padding: 0px !important;

  svg {
    width: 22px !important;
    height: 22px !important;
    border-radius: 20px !important;
  }
}

.brand-filter-sub-menu {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 0;
}

.brand-filter-sub-menu-align-left {
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--bdrColor);
}

.brand-filter-sub-menu-align-left>input {
  margin: 0;
}

.brand-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.brand-apply-btn {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 70.5px;
  padding-right: 70.5px;
  box-shadow: 0px 1px 2px rgba(169, 208, 246, 0.25);
  background: var(--primary);
  border-radius: 12px;
  border: none;
  margin-top: 16px;
  margin-bottom: 16px;
  color: white;
}

.brand-apply-btn:hover {
  background: #56e2b8;
  color: #fff;
}

.brand-apply-btn:disabled {
  background: var(--primary);
  opacity: 0.25;
}

.horizontal-brand-line {
  border: 1px solid var(--bdrColor);
  width: 100%;
}

.brand-rating-input {
  width: 100px;
  border-radius: 10px;
  text-align: center;
  border: 1px solid var(--bdrColor);
}

.clear-all {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--primary);
  border: none;
  background-color: white;
}

.disable-row .dropdown-toggle {
  opacity: 0.5;
}

.ratingsWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.tc-input {
  width: 90.8%;
}

.ratingsText {
  display: block;
  width: 100%;
}

.star-ratings {
  display: flex !important;
  direction: ltr;
}

.react-select__input-container .css-6j8wv5-Input {
  box-shadow: 0px !important;
}

.form-switch .form-check-input:checked {
  background-color: var(--primary);
  border: 1px solid var(--primary);
}

.table thead th span {
  cursor: pointer;
}

.form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}

.dropdown-defalut {
  color: grey;
  font-size: 12px;
}

.disable-span {
  display: none;
}

.dropdown-badge {
  background: rgba(160, 85, 10, 0.1);
  border-radius: 6px;
  padding: 2px 8px;
  color: var(--primary);
  font-size: 14px;
  font-weight: 500;
  margin-left: 15px;
}

.dropdown-value {
  color: black;
}

.save-btn-change:hover {
  color: #fff;
  cursor: pointer;
}

.card.card.disable-row,
td.disable-row {
  background: rgba(22, 70, 101, 0.05);
}

div.card.disable-row .brandTitle,
div.card.disable-row .global__img,
div.card.disable-row .reviewDisplay,
div.card.disable-row .brandIcons span,
td.disable-row .staff-img,
td.disable-row .global__img,
td.disable-row p,
td.disable-row button {
  opacity: 0.5;
}

.relative-color {
  position: relative;
  z-index: 999;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  top: 2px;
  font-weight: 500;
  font-size: 18px;
  line-height: 34px;
  width: 100%;
}

.buttons-Text {
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  color: #292d32;
  transition: all 0.3s;
}

.edit-button {
  color: var(--primary);
  border: 1px solid var(--primary);
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25));
  border-radius: 12px;
  background: #ffffff;
  transition: all 0.3s;
}

.d-flex .form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0px;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0px;
}

.d-flex label.form-check-label {
  padding-left: 5px;
}

input[type="checkbox"]:focus,
input[type="radio"]:focus {
  box-shadow: none;
}

.d-flex .form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0px;
}

.form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}

.Valid-from {
  margin-top: 24px;
}

.button-text-color {
  background: #ffffff;
  box-shadow: 0px 0px 2px rgb(0, 0, 0 / 25%);
  border-radius: 12px;
  color: #292d32;
  border: 0.5px solid #ebebeb;
}

.Edit-Buttons-Text,
.Edit-Buttons-Text:hover,
.Edit-Buttons-Text:active,
.Edit-Buttons-Text:focus-visible {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border: none;
  color: var(--primary) !important;
  background: #fff !important;
}

.save-btn-change:hover {
  color: #fff;
  cursor: pointer;
}

.brand-modal {
  width: 333px;
  background: white;
  position: absolute;
  z-index: 10;
  top: 0%;
  right: 20%;
  border: 1px solid var(--bdrColor);
  box-shadow: 0px 1px 2px rgba(169, 208, 246, 0.25);
  border-radius: 12px;
  z-index: 999;
}

.brand-filter-menu {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.brand-filter-sub-menu {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 0;
}

.brand-filter-sub-menu-align-left {
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--bdrColor);
}

.brand-filter-sub-menu-align-left>input {
  margin: 0;
}

.brand-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.brand-apply-btn {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 70.5px;
  padding-right: 70.5px;
  box-shadow: 0px 1px 2px rgba(169, 208, 246, 0.25);
  background: var(--primary);
  border-radius: 12px;
  border: none;
  margin-top: 16px;
  margin-bottom: 16px;
  color: white;
}

.brand-apply-btn:hover {
  background: #56e2b8;
  color: #fff;
}

.brand-apply-btn:disabled {
  background: var(--primary);
  opacity: 0.25;
}

.horizontal-brand-line {
  border: 1px solid var(--bdrColor);
  width: 100%;
}

.brand-rating-input {
  width: 100px;
  border-radius: 10px;
  text-align: center;
  border: 1px solid var(--bdrColor);
}

.clear-all {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--primary);
  border: none;
  background-color: white;
}

.disable-row .dropdown-toggle {
  opacity: 0.5;
}

.ratingsWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.tc-input {
  width: 90.8%;
}

.ratingsText {
  display: block;
  width: 100%;
}

.star-ratings {
  display: flex !important;
  direction: ltr;
}

.react-select__input-container .css-6j8wv5-Input {
  box-shadow: 0px !important;
}

.form-switch .form-check-input:checked {
  background-color: var(--primary);
  border: 1px solid var(--primary);
}

.table thead th span {
  cursor: pointer;
}

.form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}

.dropdown-defalut {
  color: grey;
  font-size: 12px;
}

.disable-span {
  display: none;
}

.dropdown-badge {
  background: rgba(160, 85, 10, 0.1);
  border-radius: 6px;
  padding: 2px 8px;
  color: var(--primary);
  font-size: 14px;
  font-weight: 500;
  margin-left: 15px;
}

.dropdown-value {
  color: black;
}

.link-text {
  color: #2bbc91;
  text-decoration: underline;
  margin-bottom: 8px;
}

.dropdown-size {
  font-size: 15px;
}

.save-btn-change:hover {
  color: #fff;
  cursor: pointer;
}

.card.card.disable-row,
td.disable-row {
  background: rgba(22, 70, 101, 0.05);
}

div.card.disable-row .brandTitle,
div.card.disable-row .global__img,
div.card.disable-row .reviewDisplay,
div.card.disable-row .brandIcons span,
td.disable-row .staff-img,
td.disable-row .global__img,
td.disable-row p,
td.disable-row button {
  opacity: 0.5;
}

.form-control-color {
  height: 48px;
  border-radius: 12px;
}

.relative-color {
  position: relative;
  z-index: 999;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  top: 2px;
  font-weight: 500;
  font-size: 18px;
  line-height: 34px;
  width: 100%;
}

.buttons-Text {
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  color: #292d32;
  transition: all 0.3s;
}

.edit-button {
  color: var(--primary);
  border: 1px solid var(--primary);
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25));
  border-radius: 12px;
  background: #ffffff;
  transition: all 0.3s;
}

.d-flex .form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0px;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0px;
}

.d-flex label.form-check-label {
  padding-left: 5px;
}

input[type="checkbox"]:focus,
input[type="radio"]:focus {
  box-shadow: none;
}

.d-flex .form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0px;
}

.form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}

.Valid-from {
  margin-top: 24px;
}

.button-text-color {
  background: #ffffff;
  box-shadow: 0px 0px 2px rgb(0, 0, 0 / 25%);
  border-radius: 12px;
  color: #292d32;
  border: 0.5px solid #ebebeb;
}

.Edit-Buttons-Text,
.Edit-Buttons-Text:hover,
.Edit-Buttons-Text:active,
.Edit-Buttons-Text:focus-visible {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border: none;
  color: var(--primary) !important;
  background: #fff !important;
}

.save-btn-change:hover {
  color: #fff;
  cursor: pointer;
}

.brand-modal {
  width: 333px;
  // width: 580px;
  background: white;
  position: absolute;
  z-index: 10;
  top: 0%;
  right: 20%;
  border: 1px solid var(--bdrColor);
  box-shadow: 0px 1px 2px rgba(169, 208, 246, 0.25);
  border-radius: 12px;
  z-index: 999;
}

.brand-filter-menu {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.brand-filter-sub-menu {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 0;
}

.brand-filter-sub-menu-align-left {
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--bdrColor);
}

.brand-filter-sub-menu-align-left>input {
  margin: 0;
}

.brand-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.brand-apply-btn {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 70.5px;
  padding-right: 70.5px;
  box-shadow: 0px 1px 2px rgba(169, 208, 246, 0.25);
  background: var(--primary);
  border-radius: 12px;
  border: none;
  margin-top: 16px;
  margin-bottom: 16px;
  color: white;
}

.brand-apply-btn:hover {
  background: #56e2b8;
  color: #fff;
}

.brand-apply-btn:disabled {
  background: var(--primary);
  opacity: 0.25;
}

.horizontal-brand-line {
  border: 1px solid var(--bdrColor);
  width: 100%;
}

.brand-rating-input {
  width: 100px;
  border-radius: 10px;
  text-align: center;
  border: 1px solid var(--bdrColor);
}

.clear-all {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--primary);
  border: none;
  background-color: white;
}

.disable-row .dropdown-toggle {
  opacity: 0.5;
}

.ratingsWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.tc-input {
  width: 90.8%;
}

.ratingsText {
  display: block;
  width: 100%;
}

.star-ratings {
  display: flex !important;
  direction: ltr;
}

.react-select__input-container .css-6j8wv5-Input {
  box-shadow: 0px !important;
}

.form-switch .form-check-input:checked {
  background-color: var(--primary);
  border: 1px solid var(--primary);
}

.table thead th span {
  cursor: pointer;
}

.form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}

.dropdown-defalut {
  color: grey;
  font-size: 12px;
}

.disable-span {
  display: none;
}

.dropdown-badge {
  background: rgba(160, 85, 10, 0.1);
  border-radius: 6px;
  padding: 2px 8px;
  color: var(--primary);
  font-size: 14px;
  font-weight: 500;
}

span.dropdown-badge {
  margin-left: 0;
}

.dropdown-value {
  color: black;
}

.save-btn-change:hover {
  color: #fff;
  cursor: pointer;
}

.card.card.disable-row,
td.disable-row {
  background: rgba(22, 70, 101, 0.05);
}

div.card.disable-row .brandTitle,
div.card.disable-row .global__img,
div.card.disable-row .reviewDisplay,
div.card.disable-row .brandIcons span,
td.disable-row .staff-img,
td.disable-row .global__img,
td.disable-row p,
td.disable-row button {
  opacity: 0.5;
}

.disable-row .reviewDisplay,
.disable-row .brandTitle,
.disable-row .brandIcons span {
  opacity: 0.5;
}

body .drop_box {
  padding: 0 20px;
}

body .dropdown .dropdown-toggle:active,
body .dropdown .dropdown-toggle:hover {
  background-color: transparent;
}

div.react-datepicker {
  border-color: var(--bdrColor);
}

div.react-datepicker__header {
  background: var(--bdrColor);
  border-bottom: 1px solid var(--bdrColor);
}

.customeDisable {
  background: rgba(22, 70, 101, 0.05);
}

.exper-li.customeDisable .exper-name,
.exper-li.customeDisable .dis-dropdown .dropdown-toggle {
  opacity: 0.5;
}

// .react-select__menu .react-select__menu-list>div:last-child>div{border-bottom:0;}

div.react-datepicker__header {
  background: var(--bdrColor);
  border-bottom: 1px solid var(--bdrColor);
}

.customeDisable {
  background: rgba(22, 70, 101, 0.05);
}

.exper-li.customeDisable .exper-name,
.exper-li.customeDisable .dis-dropdown .dropdown-toggle {
  opacity: 0.5;
}

.dropdown-value-placeholder {
  color: #8e8e8e;
}

.frenchModal .modal-content {
  border-radius: 12px;
  border: none;
  outline: 0;
  padding: 0px;
}

.frenchModal .modal-content .modal-header {
  display: block;
}

.frenchModal .modal-content .modal-header h6 {
  color: #292d32;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  font-family: "Cairo";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
}

.frenchModal .modal-content>p {
  text-align: center;
  font-family: "Cairo";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #909090;
  margin-top: 15px;
  margin-bottom: 32px;
}

.frenchModal .modal-content .modal-header {
  border-bottom: 0;
  padding: 2rem 0 0 0;
}

.frenchModal .Experience-btn-modal {
  align-items: center;
  gap: 18px;
  margin-bottom: 2rem;
  margin-top: 40px;
}

.frenchModal .modal-body {
  padding: 0rem 2rem 2rem 2rem;
}

.check__boxx {
  text-align: left;
}

.frenchModal .modal-body .check__boxx>h6 {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #292d32;
}

.frenchModal .modal-body>ul>li .dashedBorder {
  width: 79px;
  border: 1px dashed var(--primary);
  border-radius: 8px;
  align-items: center;
  text-align: center;
  padding: 5px 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--primary);
  cursor: pointer;
}

.frenchModal .modal-body>ul>li .dashedBorder>span {
  padding: 0 5px;
}

.frenchModal .modal-body>ul>li .contentWpr>h6 {
  margin-bottom: 0;
  font-family: "Cairo";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #292d32;
}

.frenchModal .modal-body>ul>li .contentWpr {
  padding-left: 10px;
}

.frenchModal .modal-body>ul>li .contentWpr>h6>span {
  color: #909090;
}

.frenchModal .modal-body>ul>li {
  align-items: center;
  margin-bottom: 18px;
}

.frenchModal .modal-body>ul {
  margin-top: 16px;
  max-height: 300px;
  overflow-y: scroll;
}

.frenchModal .Experience-btn-modal button {
  width: 120px;
  height: 48px;
  margin: 0 auto;
  background: var(--primary);
  font-family: "Cairo";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  box-shadow: 0px 1px 2px rgba(169, 208, 246, 0.25);
  border-radius: 12px;
  color: #fff;
}

.mytooltip>.tooltip-inner {
  max-width: 50%;
  text-align: center;
  justify-content: center;
  margin: 0 auto;
}

.mytooltip2>.tooltip-inner {
  max-width: 70%;
  text-align: center;
  justify-content: center;
  margin: 0 auto;
}

.multipul-languages .ellipsis .dropdown-menu {
  height: 400px;
  overflow-y: scroll;
  overflow-x: auto;
  background: #fff;
}

.multipul-languages .ellipsis .dropdown-menu::-webkit-scrollbar-track,
.multipul .ellipsis .dropdown-menu::-webkit-scrollbar-track {
  background-color: #fff;
  margin: 20px 0;
}

.multipul .ellipsis .dropdown-menu {
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: auto;
  background: #fff;
}

.multipul-languages .ellipsis .dropdown-menu .dropdown-item>.radio-and-check>div:first-child {
  width: calc(100% - 90px);
}

.multipul-languages .ellipsis .dropdown-menu .form-check-label {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 18px;
}

.form-check-label {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 1.2;
}

.ellipsis.dropdown .d-flex>div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ellipsis.dropdown .d-flex>div span.dropdown-defalut {
  padding-top: 1px;
  margin-left: 5px;
}

.multi {
  width: 85%;
}

.select-height {
  font-size: 14px;
  height: 48px;
}

.select-height>span {
  position: absolute;
  right: 12px;
}

.select-default {
  color: gray !important;
}

.dropdown.show button .d-Arrow img {
  transform: rotate(180deg);
  width: 16px;
  height: 100%;
}

.dropdown button .d-Arrow img {
  width: 16px;
  height: 100%;
}

.exp-dropdown {
  height: 250px;
  overflow-y: scroll;
}

.exp-label {
  text-overflow: ellipsis;
  width: 91%;
  overflow: hidden;
}

.brandScroll {
  height: 150px;
  overflow-y: scroll;
}

div#dropdown-menu\ homeDropDown {
  max-height: 280px;
  overflow-y: scroll;
}

.status {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.active-status {
  background-color: #e5ffe8;
  color: #3fe087;
  width: 80px !important;
  border-radius: 20px;
}

.disabled-status {
  background-color: #ffe5e5;
  color: #ed1c24;
  width: 80px !important;
  border-radius: 20px;
}

.gapDiv {
  gap: 8px;
}

.borderBottom {
  border-bottom: 1px solid rgba(43, 188, 145, 0.25);
  margin-top: 24px;
  margin-bottom: 29px;
}

.iconDelete {
  text-align: end;
  position: absolute;
  width: 100%;
  margin-top: -10px;
}