.input {
  background-color: white !important;
  color: black !important;
  width: 100%;
  font-size: 14px !important;
  line-height: normal !important;
  display: flex;
  justify-content: space-between;
  padding: 4px 1rem !important;
  border-radius: 12px !important;
  border: 1px solid rgba(43, 188, 145, 0.25);
  background: var(--white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(169, 208, 246, 0.25);
}

.iconBtn {
  display: flex;
  gap: 0.5rem;
  padding: 6px 12px !important;
  border: 0;
  background: var(--white, #fff);
  color: #2bbc91;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.iconBtn svg {
  font-size: large;
}

.tableRow {
  display: grid;
  grid-template-columns: 2rem auto auto 3rem;
  text-align: center;
  border-bottom: 1px solid rgba(43, 188, 145, 0.25);
  font-size: 14px;
  padding: 12px 1rem;
  color: #292d32;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  gap: 1.5rem;
}

.tableRow > span:first-child {
  padding: 8px 0;
}
