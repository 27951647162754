/* :root {
  --primary: var(--primary);
  --primaryHover: rgba(0, 0, 0, 0.25);
  --primaryBG-8: rgba(22, 70, 101, 0.08);
  --primaryBG-10: rgba(22, 70, 101, 0.1);
  --primaryGrey: #a2b5c1;
  --orangeColor: #fd6919;
  --redColor: #ed1c24;
  --lightGreen: #30f100;
  --darkColor: #292d32;
  --greyColor: #909090;
  --white: #fff;
  --bdrColor: rgba(43, 188, 145, 0.15);
  --boxShadow: 0px 1px 2px rgba(169, 208, 246, 0.25);
  --Radius12: 12px;
  --Radius8: 8px;
  --Radius6: 6px;
  --Radius4: 4px;

  /* Width Variable */
  /* --width120: 120px;
  --width200: 200px; */

  /* Fonts Variable */
  /* --font28: 28px;
  --font20: 20px;
  --font18: 18px;
  --font16: 16px;
  --font14: 14px;
  --font12: 12px;
  --weight200: 200;
  --weight300: 300;
  --weight400: 400;
  --weight500: 500;
  --weight600: 600;
  --weight700: 700;
  --weight800: 800;
  --weight900: 900;
  --weight1000: 1000; */

  /* Lable color */
  /* --labelPurpleBG: rgba(148, 119, 171, 0.15);
  --labelBlueBG: rgba(0, 156, 248, 0.15);
  --labelYellowBG: rgba(299, 157, 35, 0.15);
  --labelGreenBG: rgba(43, 188, 145, 0.15); */

  /* --labelPurpleColor: rgba(148, 119, 171, 1); */
  /* --labelBlueColor: rgba(0, 156, 248, 1); */
  /* --labelYellowColor: rgba(299, 157, 35, 1);
  --labelGreenColor: rgba(43, 188, 145, 1); */
/* } */

:focus,
:focus-visible,
.form-select:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: none;
}

@media (max-width: 2500px) {
  .PerformaceOverTime {
    height: auto;
  }
  .PerformaceOverTime canvas {
    height: auto;
  }
}

@media (max-width: 1500px) {
  body .Wrapper {
    padding: 30px 30px 30px 290px;
  }

  body .Logo {
    padding: 26px 0 27px 0;
  }

  body .feedbackFlex ul li p strong {
    font-size: 14px;
  }

  body .contentHead {
    padding: 0 0 20px;
    display: flex;
    justify-content: space-between;
  }

  body .sidebarContent {
    width: 260px;
  }

  body .navBar {
    height: calc(100vh - 90px);
  }

  body .navBar > .navDIV {
    height: calc(100vh - 182px);
  }

  body .navBar > .navDIV ul.navUL > li + li {
    padding-top: 6px;
  }

  body .navBar > .navDIV ul.navUL > li a,
  body .navBar > .navDIV ul.navUL > li .dropdown-Link {
    padding: 9px 12px;
    font-size: var(--font14);
    border-radius: 6px;
  }

  body .FilterBox {
    margin: 0 0 1.5rem;
    border-radius: 6px;
    padding: 5px 5px;
    width: 100%;
    position: relative;
  }

  body .datepicker input.form-control,
  body .customSelect select.selectpicker {
    font-size: 14px;
    width: 100%;
  }

  body .multiBoxes {
    margin: 0 0 1.5rem;
  }

  body .multiBoxesDiv {
    padding: 10px 10px 10px 70px;
    min-height: 72px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  body .multiBoxesDiv span {
    top: 10px;
    left: 10px;
    width: 50px;
    height: 50px;
  }

  body .multiBoxesDiv span img {
    width: 30px;
  }

  body .multiBoxesDiv h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 17px;
    line-height: 1.25;
  }

  body .multiBoxesDiv p {
    line-height: 1.25;
  }

  body .box {
    /* padding: 0; */
    margin: 0 0 1.5rem;
  }

  body .box h2 {
    margin: 0 0 1.5rem;
  }

  body .PerformanceGraph {
    overflow: auto;
  }

  body .table thead th {
    white-space: nowrap;
  }

  body .contentHead .btnIconDashBDR {
    height: 40px;
  }

  body .btnIconDashBDR {
    padding: 7px 12px;
    border-radius: 6px;
    font-size: 14px;
  }

  body .btnIconDashBDR em img {
    width: 20px;
  }

  body .contentHead h1 {
    font-size: 24px;
    margin: 0;
    width: auto;
  }

  body .box h2 {
    font-size: 18px;
  }

  body .table thead th:last-child {
    border-radius: 0 6px 6px 0;
  }

  body .table thead th:first-child {
    border-radius: 6px 0 0 6px;
  }

  body .feedbacksLable ul li {
    padding: 8px;
  }

  body .feedbacksLable ul,
  body .inputWrap input,
  body .feedbackUsers,
  body .feedbackRight,
  body .nav-tabs .nav-link.active,
  body .brandInfo,
  body .brandFilter button,
  body .dropdown-menu,
  body .feedbacksLable ul li.active {
    border-radius: 6px;
  }

  body .brandAccordion.accordion > .card > .card-header {
    padding: 10px;
  }

  body .card-body {
    padding: 10px;
  }

  body .modal-content {
    padding: 20px;
    border-radius: 6px;
  }

  body .dropdown-menu li a {
    padding: 8px 10px;
  }

  body .modal-header {
    padding: 0 0 1.5rem;
  }

  body .inputWrap label {
    margin: 0 0 4px;
  }

  body .Experience-btn-modal {
    margin-top: 2rem;
  }

  body .h4,
  body h4 {
    font-size: calc(1.25rem + 0vw);
  }

  body .Experience-btn-modal .edit-data,
  body .Cancel-passwor {
    border-radius: 6px;
    padding: 9px 40px;
  }

  body button.send-modal {
    height: 40px;
    border-radius: 6px;
  }

  body .modalAddBranch .modal-dialog {
    padding: 0 20px;
  }

  /* body .brandTable {
    overflow: auto;
  } */

  body .ellipsis button {
    /* height: 40px; */
    border-radius: 6px;
    height: auto;
  }

  body .experience-inner-content.input-box-profile {
    border-radius: 6px;
  }

  body .input-box-profile {
    border-radius: 6px;
  }

  body .dropdown-menu ul li:first-child a {
    border-radius: 6px 6px 0 0;
  }

  body .dropdown-menu ul li:last-child a {
    border-radius: 0 0 6px 6px;
  }

  body .tab_cstm_wrapper {
    width: calc(100% - 290px);
  }

  /* body .brand-mobile-phone {
    max-width: 240px;
  } */

  body .form-select {
    padding: 9px 32px 9px 12px;
    border-radius: 6px;
  }

  /* body div{padding:0 8px; border-radius:6px;} */
  body .form-switch {
    padding: 9px 10px 9px 45px;
  }

  body .form-control-color {
    height: 40px;
  }

  body .button-color {
    height: 40px;
  }

  body .btn {
    padding: 9px 20px;
    border-radius: 6px;
  }

  body .resend-invitation {
    padding: 9px 12px;
    border-radius: 20px;
  }

  body .btn.close_btn {
    padding: 0;
  }

  body .btn.btnIcon {
    padding: 8px 20px;
    border-radius: 6px;
  }

  body .staff-wrp {
    padding: 1rem 0px 1rem 0px;
  }

  .customersTable {
    width: 100%;
    overflow: scroll;
    height: calc(100vh - 250px);
  }
  .customersTable::-webkit-scrollbar {
    width: 10px;
  }

  .customersTable .table thead {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: #fff;
  }

  body .form-control {
    padding: 10px 12px;
    border-radius: 6px;
  }

  body .customSelect .dropdown-toggle,
  body .ellipsisDrop button {
    border-radius: 6px;
  }

  body .dropdown-menu span:last-child {
    border-radius: 0 0 6px 6px;
  }

  body .dropdown-menu span:first-child {
    border-radius: 6px 6px 0 0;
  }

  body div .input-box-profile {
    padding: 10px 10px;
    align-items: center;
    text-overflow: ellipsis;
  }

  body .tab_panel_new {
    border-radius: 6px;
  }

  body .nav-tabs .nav-link {
    padding: 8px 5px;
    width: 100%;
  }

  body .dropdown-menu li:first-child a {
    border-radius: 6px 6px 0 0;
  }

  body .dropdown-menu li:last-child a {
    border-radius: 0 0 6px 6px;
  }

  body .form-control.css-b62m3t-container {
    padding: 0 8px;
  }

  body .react-select__control .react-select__value-container {
    padding: 0;
  }

  body .feedbackRightHead ul li:last-child {
    min-width: calc(100% - 80%);
    justify-content: center;
    margin-top: 0;
  }

  /* 
.profile page */
  .profile-box {
    max-width: 350px;
    margin: 12px auto 0;
  }

  .profile {
    width: 150px;
    height: 150px;
    margin: 20px auto;
  }
  .profile-inner {
    padding: 30px 0 0 0;
  }
  .profile-inner .profile-name {
    margin: 24px 0px 0px 0px;
  }
  .profile-inner > h6 {
    margin: 0;
    padding: 0 !;
  }
  .profile-btn {
    padding: 30px 0px 25px 0px;
  }
  .profile-form {
    padding: 0px 25px;
  }
  .profile-form h3 {
    padding: 20px 0 30px 0;
  }

  .slider_btn {
    margin-left: 40px;
  }
  .slide.selected .rect-Flex > div {
    justify-content: center;
  }
  .slide.selected .sliderText {
    font-size: 12px;
  }

  .slide.selected .rect-Flex {
    width: 60%;
    text-align: left;
  }
}

@media (max-width: 1366px) {
  /* ===homepage=css== */
  .box {
    height: 450px;
  }
  .equalHeight .box {
    height: 530px;
  }
  .OverallGraph {
    width: 350px;
    height: 350px;
  }
  .form-control {
    font-size: 14px;
    line-height: 1;
  }
  /* customer page*/

  .customerSuggestions.content {
    height: 430px;
  }
  .feedbackRightHead ul li > .DominosFeedback {
    width: 215px;
  }
  .cutotherDetails h3 {
    font-size: 20px;
    line-height: 35px;
  }
  .cutotherDetails h5 {
    font-size: 14px;
  }
  span.d-Arrow {
    margin-left: 12px;
  }
  /* feedback page*/
  .feedbackFlex ul li p {
    font-size: 14px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .feedbackFlex ul li p strong {
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: auto;
  }
  .feedBackWpr .nav-tabs .nav-item {
    font-size: 14px;
  }
  .feedbackFlex ul li:last-child p {
    margin-right: 10px;
  }
  .feedBackWpr .feedbackRightHead ul li h3 {
    width: 100%;
    line-height: 20px;
    max-width: 100%;
    line-break: auto;
    font-size: 15px;
  }
  .feedBackWpr .feedbackRightHead ul li p {
    font-size: 14px;
  }
  .feedbackRightSectionUl span {
    font-size: 14px;
  }
  .feedBackWpr .custFeedback p {
    line-height: 16px;
  }
  .feedbackRightSection .feedbackRightSectionUl {
    padding: 10px 0px 10px 0;
  }
  .feedbackRightSectionLi strong,
  .feedbackRightSectionLi h6,
  .custFeedback strong {
    font-size: 14px;
  }
  .feedbackRightSectionLi .mb-3 {
    margin-bottom: 10px !important;
  }
  .feedbackFlex ul li {
    gap: 10px;
  }
  /* brand page css*/
  .brandTitle strong {
    font-size: 14px;
    margin-left: 5px;
  }
  .nav-tabs .nav-link {
    font-size: 14px;
  }
  /* Links and QR Codes css */

  .brandTitle strong {
    margin-right: 10px;
  }
  .cstpFeedbacks > table > tbody > tr > td > strong {
    font-size: 15px;
  }
  .cstpFeedbacks > table > tbody > tr > td > span {
    font-size: 15px;
  }
  .cutotherDetails h5 {
    font-size: 15px;
  }
}

/* Date8/2023css Today  */

@media (max-width: 1099px) {
  body .btn {
    /* height: auto; */
    padding: 9px 10px;
    border-radius: 6px;
    line-height: 1.3;
  }
  .resend-invitation {
    line-height: 14px;
  }

  .add-new-user {
    padding: 20px 10px;
  }

  body .Wrapper {
    padding: 30px 30px 30px 20px;
  }

  body .Logo {
    width: auto;
    padding: 0;
    display: block;
    text-align: left;
    position: absolute;
    left: 15px;
    top: 15px;
    z-index: 999;
  }

  body .sidebarContent .nav {
    display: none;
  }

  body .contentWrap {
    display: block;
  }

  body .sidebarContent {
    z-index: 999;
    width: 100%;
    padding: 0.5rem 1rem;
    height: 70px;
    display: flex;
    flex-direction: row-reverse;
  }

  body .MobileHead {
    display: block;
    margin-left: auto;
  }

  body .NavBox {
    position: relative;
    top: 0;
    left: 0;
    border-radius: 6px;
  }

  body .contentHead {
    padding: 0 0 10px;
    margin-top: 55px;
  }

  body .Wrapper {
    padding: 48px 20px 48px 20px;
  }

  .navBarHead {
    height: calc(100% - 100px);
  }

  body .navBar {
    height: calc(100vh - 70px);
    background: #ffff;
    margin-top: 70px;
    width: 260px;
    padding: 20px 0;
    border-right: 1.5px solid rgba(169, 208, 246, 0.75);
    box-shadow: 2px 0px 16px rgb(169 208 246 / 35%);
    left: -260px;
    top: 0;
    position: absolute;
    display: block;
    transition: ease-in-out 0.5s;
  }
  .navBar.sliderBarOpen {
    left: 0;
    transition: ease-in-out 0.5s;
  }

  /* .navBar .sliderBarOpen {
    transform: translateX(0);
    left: 0;
    display: block;
    transition: ease-in-out .5s;
  } */
  .navBar .sliderBarClose {
    transform: translateX(-100%);
  }

  .brandTitle span {
    margin: 0 0px 0 0;
  }
  .feedbackFlex ul li:last-child p {
    margin-right: 0;
    margin-left: 0;
  }

  .QRcodeInput > button {
    width: 100px;
  }

  .cust-feedback .modal-dialog,
  .max-modal .modal-dialog {
    max-width: 600px;
  }
  /* .max-modal .modal-dialog {
    max-width: 600px;
} */
  .commanModal .modal-dialog {
    max-width: 550px;
  }
  .commanModal h2,
  .commanModal p {
    font-size: 18px;
  }
  .contentWrap.custScroll .equalHeight {
    overflow-x: inherit;
  }
  .position-relative {
    position: relative !important;
    z-index: 999;
  }

  .brandTable {
    /* overflow-x: scroll; */
    width: 100%;
    padding-bottom: 10px;
  }
  .brandTable::-webkit-scrollbar {
    height: 8px;
  }
  .brandTable::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px #d2e3f3; */
    /* border-radius: 10px; */
  }

  /* Handle */
  .brandTable::-webkit-scrollbar-thumb {border-radius: 5px;}
  .box.PerformaceOverTime canvas{width: 100%;}
  .customerSuggestions.content {height: 430px; overflow-y: auto;}

  .feedbackRight {
    width: calc(100% - 360px);
  }
  .feedbackLeft {
    max-width: 330px;
    width: 100%;
  }
  .feedbackFlex ul li {
    width: 75%;
    font-size: 14px;
  }
  .feedbackFlex ul li {
    gap: 0px;
  }

  .center {
    width: 150px;
  }
}

@media (max-width: 991px) {
  .feedbackLeft {
    max-width: 100%;
    width: 100%;
  }
  .feedbackRight {
    width: 100vw;
    height: 100vh;
    padding-top: 100px;
    position: fixed; 
    top: 0;
    left: 0;
    overflow-y: scroll !important;
    overflow-x: hidden;
    background-color: rgb(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
  }
  .feedbackRightContent {
    max-width: 90%;
    height: auto;
    background-color: #fff !important;
    display: flex;
    flex-direction: column;
  }
  .ellipsis {
    border-bottom: 1px solid var(--bdrColor);
  }
  .ellipsis button {
    border: none !important;
  }
  .feedbackRightSection {
    background-color: #fff;
    width: 100% !important;
    padding: 10px;
    margin: 0px !important;
    box-sizing: border-box;
  }
  .FilterBox {
    border: none;
  }
  .hideSingleFeedback {
    top: 100% !important;
  }
  .cstpFeedbacks > table > tbody > tr > td {
    font-size: 14px;
  }
  .cutotherDetails h3 {
    font-size: 18px;
    line-height: 30px;
  }
  .cutotherDetails h5 {
    font-size: 14px;
  }
  .custRated ul li p {
    font-size: 14px;
  }
  .brand-wpr {
    justify-content: space-between;
  }
  .commanModal p strong {
    font-weight: var(--weight700);
    font-size: 14px;
  }
  .commanModal p {
    font-size: 14px;
    line-height: 25px;
  }
  ul.languageInner-modal .d-flex p {
    font-size: 16px;
  }
  .modalImprove .modal-content .modal-header .h4 {
    font-size: 16px;
  }
  .modalImprove .modal-content .modal-header {
    padding: 1rem 0 1.5rem 0;
  }

  .exp-modal-body .modalAddBranchLeft {
    width: 100%;
    max-width: 350px;
  }
  .exp-modal-body .modalAddBranchRight {
    max-width: calc(100% - 380px);
    width: 100%;
  }
  /* .exp-modal-body .catalogueModal {margin-top: 20px;} */
  .modal.show .modal-dialog {
    transform: none;
    max-width: 700px;
  }
  .modalAddBranchLeft {
    max-width: 330px;
  }
  .modalAddBranchRight {
    max-width: calc(100% - 390px);
  }
  .brandTitle {
    text-align: left;
  }
  .brand-mobile-phone {
    margin: 0 auto;
    margin-top: 50px !important;
  }
}

@media (max-width: 768px) {
  /* .brand-mobile-phone {
    margin: 0 auto;
    margin-top: 50px !important;
} */
  .FilterBox > ul > li {
    width: 33.33%;
    position: relative;
    padding: 5px 5px;
  }

  body .multiBoxes {
    margin: 0 0 1.5rem;
    width: 100%;
  }

  .multiBoxes ul {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0 -8px;
    padding: 0;
    flex-wrap: wrap;
    gap: 0px;
  }
  .multiBoxes ul li {
    width: 50%;
    padding: 10px 8px 0;
  }
  .FilterBox:after {
    content: "";
    background: var(--bdrColor);
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
  }

  .equalHeight {
    width: 100%;
    overflow-x: scroll;
    gap: 30px;
    margin: 0;
  }
  .feedFilter ul li {
    width: 33.33% !important;
  }
  .feedbackLeft {
    max-width: 280px;
    width: 100%;
  }
  .feedbackRight {
    width: calc(100% - 305px);
  }
  .feedBackWpr .feedbackRightHead ul li p {
    font-size: 13px;
  }
  .feedbackFlex ul li p {
    font-size: 13px;
  }
  .feedbackFlex ul li p strong {
    font-size: 13px !important;
  }
  .feedbackFlex ul li:last-child p {
    margin-right: 0;
    margin-left: 10px;
  }
  .feedbackRightSectionLi strong,
  .feedbackRightSectionLi h6,
  .custFeedback strong {
    font-size: 13px;
  }
  .feedbackRightSection
    .feedbackRightSectionUl
    .feedbackRightSectionLi
    .custFeedback
    > div
    > p,
  .feedbackRightSection
    .feedbackRightSectionUl
    .feedbackRightSectionLi
    .custFeedback
    .feedDiv
    > div
    > p {
    font-size: 13px;
  }

  .my-3 button {
    width: 100%;
    min-width: 80px;
  }

  .feedBackWpr .feedbackRight .brandAccordion {
    padding: 20px 10px 0 10px;
  }
  .brandTable {
    margin: 0 0 20px;
    overflow-x: scroll;
  }

  body .card-body {
    padding: 10px;
    /* overflow-x: scroll; */
  }

  .tab_panel_new > li {
    width: 33.33%;
  }
  .multipul-languages {
    position: relative;
    width: 100%;
  }
  .stab-table {
    margin: 0 0 1.25rem;
    width: 100%;
    overflow-x: scroll;
  }

  .stab-table::-webkit-scrollbar {
    height: 8px;
  }
  .brandTable::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px #d2e3f3; */
    border-radius: 10px;
  }

  .staff-wrp {
    gap: 10px;
  }
  /* .branches-inner {
    max-width: 100%;
    width: 100%;
  } */
  .inputWrap {
    width: 100%;
  }
  .ratings-content {
    width: 100%;
  }
  .question__inner {
    width: 60%;
  }
  .brandAccordion {
    width: 100% !important;
  }
  .Catalogues-flex > li {
    width: 50%;
  }
  .modalAddBranchLeft {
    max-width: 100%;
  }
  .catalogueModal {
    margin: 15px 0 0 0;
  }
  .modalAddBranchRight {
    max-width: 100%;
  }
  .equalHeight .col-md-6 {
    width: 100%;
    padding: 0;
  }
  .box.PerformaceOverTime {
    height: 100% !important;
  }
  .equalHeight .box {
    margin-bottom: 0;
  }

  /* .box.PerformaceOverTime {
    height: 500px;
    margin-bottom: 20px;
  } */
  .feedbackUsers {
    height: 390px;
  }
  .feedbackBody {
    gap: 15px;
  }
  .feedbackLeft {
    max-width: 100%;
    width: 100%;
  }
  .feedbackRight {
    width: 100%;
  }
  .custProfile ul {
    gap: 10px;
  }
  .custProfile ul li .mrCustm {
    margin-right: 20px;
  }
  .FilterBox > ul > li:nth-child(4):before {
    display: none;
  }
}
