.contentHead .arrowLeft {
  margin-right: 10px;
  width: 28px;
}

.contentHead .arrowLeft img {
  width: 100%;
}

.CustomerWpr {
  border: 1px solid var(--bdrColor);
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 24px;
}

.custProfile ul {
  justify-content: space-between;
}

.cstprofilePick {
  width: 48px;
  height: 48px;
  background: rgba(43, 188, 145, 0.15);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
}

.cstprofilePick h6 {
  margin: 0;
  font-family: "Cairo";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  color: var(--primary);
}

.cutotherDetails h3 {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 45px;
  display: flex;
  align-items: center;
  color: #292d32;
}

.cutotherDetails h3 > .cstRated {
  background: rgba(255, 214, 0, 0.15);
  border-radius: 4px;
  margin-left: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cutotherDetails h3 > .cstRated img {
  margin-left: 5px;
}

.cutotherDetails h5 {
  font-weight: 700;
  font-size: 16px;
  color: #292d32;
}

.cstpFeedbacks > table > tbody > tr > td > strong {
  margin-left: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.cstpFeedbacks > table > tbody > tr > td > strong > em {
  display: flex;
  align-items: center;
}

.custProfile ul li .mrCustm {
  margin-right: 70px;
}

.cstpFeedbacks > table > tbody > tr > td {
  padding: 10px 0 0 0;
  font-size: 16px;
}

.cstpFeedbacks > table > tbody > tr > td > span {
  color: #909090;
}

.custRated {
  padding-top: 26px;
}

.custRated > ul {
  border-top: 1px solid var(--bdrColor);
  padding: 15px 0 0 0;
  justify-content: space-between;
  align-items: center;
}

.custRated ul li .ratedProduct {
  display: flex;
  align-items: center;
}

.custRated ul li .ratedProduct .ProductImg {
  width: 48px;
  height: 48px;
  // background: #d9d9d9;
  border-radius: 12px;
}

// .custRated ul li .ratedProduct .ProductName {
//   margin-left: 12px;
// }

.custRated ul li .ratedProduct .ProductName p {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #909090;
}

.custRated ul li p {
  color: #909090;
  font-weight: 500;
  font-size: 16px;
}

.custRated ul li .ratedProduct .ProductName h6 {
  font-weight: 400;
  font-size: 14px;
  color: #292d32;
  margin-bottom: 5px;
}

.custRated ul li .ratedProduct .ProductName h6 > span,
.feedbackRightSection ul li > .custFeedback > h6 > span,
.feedbackRightSection ul li > .custFeedback > div > h6 > span, .dashboardCat {
  background: #2BBC91;
  border-radius: 6px;
  padding: 0 10px;
  color: var(--primary);
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  opacity: 0.8;
}

.dashboardCat {
  line-height: 26px;
}

.feedbackRightSection ul li > .custFeedback > div > p {
  margin: 10px 0 0 0;
  line-height: 22px;
}

.feedbackProfile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.feedback-img img {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  margin-right: 10px;
}

.feedback-img .sb-avatar {
  margin-right: 10px;
}

.sendBtn {
  margin: 20px 0;
}

.sendBtn button {
  padding: 9px 17px;
  width: 200px;
}

.cstmBox {
  border: 1px solid var(--bdrColor);
  border-radius: 12px;
  min-height: 400px;
  /* padding: 12px 16px; */
}

.cstmBox ul li > div {
  justify-content: space-between;
  align-items: center;
  padding: 12px 0 12px 0;
  padding: 0 16px;
}

.cstmBox ul li > div > h4,
.cstmBox ul li > div > h5 {
  font-weight: 700;
  font-size: 14px;
  line-height: 26px;
  color: #292d32;
}

.cstmBox ul li > div > h5 {
  color: #ed1c24;
}

.cstmBox ul li > div > h6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  color: #909090;
  margin-bottom: 0;
}

.cstmBox ul li p {
  padding: 0px 16px;
  margin-bottom: 10px;
  color: #909090;
}

.cstmBox ul .bgSelect {
  background: rgba(169, 208, 246, 0.2);
}

.cstmBox ul li {
  padding: 12px 16px;
  border-bottom: 1px solid var(--bdrColor);
}

.resolved_btn_Color {
  font-size: 16px;
  color: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 12px;
}

.resolved_btn_Color:hover {
  background-color: var(--primary);
  color: #fff;
}

.resolved,
.disable_resolved {
  min-width: 120px;
  padding: 6px 0px;
}

.btn_wpr_inner {
  padding-bottom: 20px;
}

// .filterButton button {
//   background-color: #56e2b8;
//   color: black;
//   padding: 5px;
//   border: 1px solid #56e2b8;
// }

.filterButton {
  margin: 0 0 2rem;
  background-color: #56e2b8;
  color: black;
  padding: 5px;
  border: 1px solid #56e2b8;
  border-radius: 8px;
  width: fit-content;

  button {
    background-color: inherit;
    border: none;
    min-width: fit-content;
    padding: 4px 4px;
  }
}

.filterImg {
  padding: 0px 10px;
  border-right: 1px solid black;
}

button.custApplyBtn {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary) !important;
  color: #fff !important;
  height: 40px;
}

button.custApplyBtn:hover {
  background-color: #56e2b8 !important;
  background: 1px solid #56e2b8 !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: transparent;
}

.FilterBox > ul > li .dropdown-menu > a:last-child {
  border-bottom: none;
}

.resolvedGreen {
  color: #2bbc91 !important;
  justify-content: center;
}

.unresolvedRed {
  color: #ed1c24 !important;
}

.calenderWPR,
.modalCalender {
  // z-index: 99;
  width: 350px;
  // width: 635px;
  // display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 12px 0px;
  // border: 1px solid var(--bdrColor);
  border-radius: 12px;
  margin-left: auto;
}

.modalCalender {
  border: 1px solid var(--bdrColor);
}

.calenderWPR + div {
  width: 100%;
  padding: 0px 24px;
}

.calenderWPR div.react-datepicker,
.modalCalender div.react-datepicker {
  border-color: var(--bdrColor);
  border: 0px;
  // width: 320px;
  // width: 600px;
}

.calenderWPR div.react-datepicker__header,
.modalCalender div.react-datepicker__header {
  background: transparent;
  border-bottom: 1px solid var(--bdrColor);
}

.calenderWPR .form-select,
.modalCalender .form-select {
  margin: 0 5px;
}

.calenderWPR .cal-button,
.modalCalender .cal-button {
  border-top: 1px solid var(--bdrColor);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 0 10px 0;
}

.calenderWPR .calenderButton,
.modalCalender.calenderButton {
  border-top: 1px solid var(--bdrColor);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 0 10px 0;
}

.calenderWPR .calenderButton button.custApplyBtn,
.modalCalender .calenderButton button.custApplyBtn {
  margin-bottom: 10px;
  width: 240px;
}

.calenderWPR .react-datepicker__day-names,
.modalCalender .react-datepicker__day-names {
  margin: 30px 0 0 0;
}

.calenderWPR .react-datepicker__day-name,
.modalCalender .react-datepicker__day-name,
.calenderWPR .react-datepicker__day,
.modalCalender .react-datepicker__day,
.calenderWPR .react-datepicker__time-name,
.modalCalender .react-datepicker__time-name {
  color: #000;
  display: inline-block;
  // width: 35px;
  // width: 80px;
  width: auto;
  min-width: 2rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
  font-size: 16px;
}

.cus-dropdown button {
  background-color: transparent !important;
  height: 37px !important;
}

.clrfilter {
  color: var(--primary);
  font-weight: 600;
}

.filter-btn:disabled {
  color: #909090;
  background: #ffffff;
  border: 1px solid rgba(144, 144, 144, 0.75);
  box-shadow: 0px 1px 2px rgba(169, 208, 246, 0.25);
  border-radius: 12px;
  opacity: 1;
}

.cust-td:hover {
  cursor: pointer;
}

.spinnerContainer {
  flex: 1;
  height: auto;
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  footer {
    display: none;
    position: fixed;
    bottom: 0;
  }
  header {
    display: none;
    position: fixed;
    top: 0;
  }
}
