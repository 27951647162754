.control {
  background-color: white !important;
  color: black !important;
  width: 100%;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 6px 12px !important;
  border-radius: 12px !important;
  border: 1px solid rgba(43, 188, 145, 0.25);
  background: var(--white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(169, 208, 246, 0.25);
}

.dropdownMenu {
  max-height: 15rem;
  overflow: scroll;
}

.dropdownItem {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem !important;
  gap: 0.5rem;
  margin: 0 !important;
}

.dropdownItem:hover {
  cursor: pointer;
}
