.plansContainer {
  display: flex;
  gap: 2rem;
}

.spinner {
  margin: 0;
  width: 1rem;
  height: 1rem;
}
