.banner {
  border-radius: 12px;
  border: 1px solid #2bbc91;
  background: #2bbc91;
  color: white;
  padding: 1rem 1.5rem;
  /* padding-bottom: 0.5rem; */
  display: flex;
  justify-content: space-between;
}

.paymentInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.paymentTitle {
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
}

.paymentDate {
  font-size: 22px;
  font-weight: 400;
  line-height: normal;
}

.paymentAction {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.paymentAction button {
  display: flex;
  width: 250px;
  height: 3rem;
  justify-content: center;
  align-items: center;
  border: 0;
  border-radius: 1rem;
  background: white;
  color: #2bbc91;
}

.paymentAction span {
  color: white;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
}

.spinner {
  margin: 0;
  width: 2rem;
  height: 2rem;
}
