.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  color: #292d32;
  font-size: 14px;
}

.header {
  border-radius: 1rem;
  border: 1px solid var(--green, #2bbc91);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
}

.header span {
  text-align: start;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  padding: 0 1rem;
}

.list {
  height: 16rem;
  overflow-y: scroll;
}

.row {
  height: 4rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(43, 188, 145, 0.25);
}

.row span {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 1rem;
}

.row a {
  color: #2bbc91;
  font-size: 14px;
  font-weight: 700;
  text-decoration-line: underline;
}

.header span:nth-of-type(1),
.row span:nth-of-type(1) {
  flex: 3;
}

.header span:nth-of-type(2),
.row span:nth-of-type(2) {
  flex: 7;
}

.header span:nth-of-type(3),
.row span:nth-of-type(3) {
  flex: 5;
}

.header span:nth-of-type(4),
.row span:nth-of-type(4) {
  flex: 3;
}

.header span:nth-of-type(5),
.row span:nth-of-type(5) {
  flex: 4;
  text-align: center;
  justify-content: center;
}

.details {
  font-weight: 500;
}

.amount {
  font-weight: 700;
}

.warn {
  color: #e59d23;
  font-size: 14px;
  font-weight: 700;
}

.error {
  color: #ed1c24;
  font-size: 14px;
  font-weight: 700;
}

.success {
  color: #2bbc91;
  font-size: 14px;
  font-weight: 700;
}
.spinner {
  margin: 0 auto;
  color: #2bbc91;
}
