.inputGroup {
  padding-bottom: 1rem;
}

.inputGroup input {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  padding: 6px 12px;
}

.inputGroup label {
  color: #292d32;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.labelContainer {
  display: flex;
  justify-content: space-between;
}

.labelContainer a {
  color: #2bbc91;
  text-decoration: underline;
}
