.container {
  display: flex;
  gap: 1rem;
}

.planCard {
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 1.5rem;
  border-radius: 8px;
  border: 1px solid rgba(43, 188, 145, 0.25);
}
.planName {
  color: black;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.planPrice {
  color: #292d32;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
}

.textLight {
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.container button {
  background-color: white;
  border-radius: 8px;
  border: 1px dashed #2bbc91;
  color: #2bbc91;
  padding: 0 3rem;
}
