.control {
  background-color: white !important;
  color: black !important;
  width: 100%;
  font-size: 14px !important;
  line-height: normal !important;
  display: flex;
  justify-content: space-between;
  padding: 3px 1rem !important;
  border-radius: 12px !important;
  border: 1px solid rgba(43, 188, 145, 0.25);
  background: var(--white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(169, 208, 246, 0.25);
}

.dropdownItem {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem !important;
  gap: 0.5rem;
  margin: 0 !important;
}

.dropdownItem:hover {
  cursor: pointer;
}

.container {
  display: flex;
  gap: 0.5rem;
  font-size: 1rem;
}

.select {
  padding: 3px 12px !important;
  border-radius: 12px !important;
  border: 1px solid rgba(43, 188, 145, 0.25);
  background: var(--white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(169, 208, 246, 0.25);
}

.iconBtn {
  padding: 3px 12px !important;
  border-radius: 12px !important;
  border: 1px solid rgba(43, 188, 145, 0.25);
  background: var(--white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(169, 208, 246, 0.25);
}

.iconBtn:disabled {
  opacity: 0.5;
  background-color: lightgray;
  border: 0;
  cursor: not-allowed;
}

.btn {
  margin: 1rem 0;
  padding: 0.5rem 1rem;
  width: 100%;
  border-radius: 12px !important;
  background-color: #2bbc91;
}
