.paymentCard {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  width: 15rem;
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid rgba(43, 188, 145, 0.25);
  background-color: white;
}

.paymentCardSelected {
  border: 2px solid #2bbc91 !important;
}

.paymentPlan {
  display: flex;
  justify-content: space-between;
}

.paymentName {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px; /* 125% */
}
.paymentDiscount {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px; /* 100% */
  border-radius: 6px;
  background: #f00;
  padding: 4px 8px;
}
.paymentPrice {
  color: #292d32;
  font-size: 48px;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}

.paymentType {
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}
