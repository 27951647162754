.modalBody {
  display: flex;
  gap: 1rem;
}

.modalBody > div {
  flex: 1;
}

.inputGroup {
  padding-bottom: 1rem;
}

.inputGroup input {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  padding: 6px 12px;
}

.inputGroup label {
  color: #292d32;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.brandImageWrapper {
  margin: 0 auto;
  max-width: 20rem;
}

/* ---------- THIS SHOULD BE REMOVED AFTER REFACTORING BrandImage ---------- */
/* Override default padding in BrandImage */
.brandImageWrapper section {
  padding: 0;
}
/* Override fixed dimensions in the drop_box */
.brandImageWrapper div {
  max-width: 20rem;
  max-height: 8rem;
}

/* Override fixed dimensions on preview img */
.brandImageWrapper div > img {
  width: 10rem;
  height: 8rem;
}

/* Override width preview button width*/
.brandImageWrapper section > div:nth-child(2) {
  width: 10rem;
}
/* ------------------------------------------------------------------------- */
