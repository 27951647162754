.receiptContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.receiptRow {
  display: flex;
  justify-content: space-between;
}

.receiptRow:last-child {
  padding-top: 1rem;
  border-top: 1px solid #e5e5e5;
}

.receiptItem {
  color: #292d32;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.receiptItem span:last-child {
  padding: 0 5rem;
}

.receiptPrice {
  color: #292d32;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px; /* 125% */
}
