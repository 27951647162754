@import "~bootstrap/scss/bootstrap";
@import "~react-toastify/dist/ReactToastify.min.css";

.active > .page-link,
.page-link.active {
  background-color: #2BBC91 !important;
  border: 1px solid #2BBC91 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

