.steps {
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  color: #6b7280;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #d1d5db;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.stepTab {
  flex: 1;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  border: 2px solid #d1d5db;
}

.stepTabDone {
  color: black;
}
.stepTabDone .circle {
  background-color: #2bbc91;
  border: 2px solid #2bbc91;
  color: white;
}

.stepTab[tabindex="0"] {
  color: #2bbc91;
}
.stepTab[tabindex="0"] .circle {
  border: 2px solid #2bbc91;
}
