.message {
  color: #2bbc91;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px; /* 150% */
  text-align: center;
  padding: 1.5rem;
}

.btn {
  margin-top: 6rem;
  border-radius: 12px;
  background: #2bbc91;
  width: 100%;
  height: 2.5rem;
}
.btnSec {
  margin-top: 6rem;
  border-radius: 12px;
  color: #2bbc91;
  background: white;
  width: 100%;
  height: 2.5rem;
}

.spinner {
  margin: 0 !important;
  width: 1rem !important;
  height: 1rem !important;
}
