.feedbacksLable {
  margin: 0 0 24px;
}

.feedbacksLable ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  background: var(--primaryBG-8);
  border-radius: var(--Radius12);
}

.feedbacksLable ul li {
  width: 33.33%;
  text-align: center;
  align-items: center;
  display: flex;
  padding: 12px;
  justify-content: center;
}

.feedbacksLable ul li strong {
  font-size: var(--font16);
  font-weight: var(--weight500);
  margin: 0 12px 0 0;
  line-height: 20px;
}

.feedbacksLable ul li span {
  background: #b62f2f;
  padding: 2px 5px;
  display: block;
  border-radius: 500px;
  line-height: 20px;
  color: var(--white);
  font-size: var(--font12);
  font-weight: var(--weight700);
  min-width: 32px;
  text-align: center;
}

.feedbacksLable ul li.active {
  background: var(--primary);
  border-radius: var(--Radius12);
}

.feedbacksLable ul li.active strong {
  color: var(--white);
}

.feedbacksLable ul li.active span {
  background: var(--orangeColor);
}

.feedbackBody {
  display: flex;
  flex-wrap: wrap;
}

.feedbackLeft {
  max-width: 375px;
  width: 100%;
}

.feedbackLeft .feedbackSearch {
  margin-bottom: 1.5rem;
}

.feedbackRight {
  width: calc(100% - 400px);
  margin-left: auto;
  border: 1px solid var(--bdrColor);
  border-radius: var(--Radius12);
}

.feedbackRight .brandAccordion {
  padding: 20px;
}

.feedbackRight .brandAccordion .card-header {
  margin-bottom: 20px;
}

.feedbackUsers {
  border: 1px solid var(--bdrColor);
  border-radius: var(--Radius12);
  height: 602px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.feedbackUsersUL li:first-child .feedbackUsersBox {
  border-radius: 5px 5px 0 0;
}

.feedbackUsersUL li:last-child .feedbackUsersBox {
  border-radius: 0 0 5px 5px;
}

.feedbackUsers::-webkit-scrollbar {
  display: none;
}

.feedbackUsersUL {
  margin: 0;
  padding: 0;
  list-style: none;
}

.feedbackUsersUL li {
  width: 100%;
}

.feedbackUsersUL li + li {
  border-top: 1px solid var(--bdrColor);
}

.feedbackUsersBox {
  padding: 12px 16px;
}

.feedbackUsersHead {
  display: flex;
  align-items: center;
  margin: 0 0 6px;
}

.feedbackUsersHead strong {
  font-weight: var(--weight700);
  font-size: var(--font14);
  color: var(--darkColor);
  line-height: 26px;
}

.feedbackUsersHead em {
  margin: 0 0 0 10px;
  background: var(--greyColor);
  border-radius: 100px;
  min-width: 24px;
  text-align: center;
  color: var(--white);
  font-size: var(--font12);
  font-weight: var(--weight400);
  display: inline-block;
  vertical-align: top;
  line-height: 26px;
  font-style: normal;
}

.feedbackUsersHead span {
  margin-left: auto;
  font-size: 14px;
}

.feedbackUsersFTR {
  display: flex;
  align-items: center;
}

// .feedbackUsersFTR h3 {
//   margin: 0;
//   padding: 0;
//   color: var(--greyColor);
//   font-size: var(--font14);
//   font-weight: var(--weight500);
//   line-height: 26px;
//   display: flex;
//   align-items: center;
// }

.feedbackUsersFTR h3 strong {
  color: var(--darkColor);
  margin: 0 6px;
}

.feedbackUsersFTR span {
  margin-left: auto;
  color: var(--greyColor);
  font-size: var(--font14);
  font-weight: var(--weight500);
  line-height: 26px;
}

.feedbackUsersFTR em {
  font-style: normal;
  line-height: 26px;
}

.feedbackUsersFTR em img {
  line-height: 26px;
  display: inherit;
}

.feedbackUsersUL li.active .feedbackUsersBox {
  background: rgba(43, 188, 145, 0.05);
}

.feedbackUsersUL li.active .feedbackUsersHead em {
  background: var(--orangeColor);
}

.feedbackUsers .mCSB_inside > .mCSB_container {
  margin: 0;
}

.feedbackUsers .mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background: var(--primaryBG-10);
}

.feedbackRightHead {
  border-bottom: 1px solid var(--bdrColor);
  padding: 10px 24px;
}

.feedbackRightHead ul li:first-child {
  width: calc(100% - 40%);
}

.feedbackRightSection {
  margin: 0 20px 0 10px;
}

.feedbackRightSection .feedbackRightSectionUl {
  padding: 27px 0px 10px 0;
  width: 100%;
}

.feedbackRightSection .feedbackRightSectionUl .feedbackRightSectionLi {
  border-bottom: 1px solid var(--bdrColor);
  padding: 20px 0 10px 0;
}

.feedbackRightSection
  .feedbackRightSectionUl
  .feedbackRightSectionLi
  .custFeedback {
  max-width: 470px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

// .feedbackRightSection
//   .feedbackRightSectionUl
//   .feedbackRightSectionLi
//   .custFeedback::before{
//     position: absolute;
//     content: "";
//     width: 100%;
//     background-color: #101820;
//     height: 1px;
//     bottom: 0;
//     left: 0;
//   }

// .feedbackRightSection
//   .feedbackRightSectionUl
//   .feedbackRightSectionLi
//   .custFeedback
//   .feedDiv {
//   width: 80%;
//   overflow: hidden;
//   white-space: nowrap;
//   text-overflow: ellipsis;
// }

.feedbackRightSection
  .feedbackRightSectionUl
  .feedbackRightSectionLi
  .custFeedback
  > div
  > p,
.feedbackRightSection
  .feedbackRightSectionUl
  .feedbackRightSectionLi
  .custFeedback
  .feedDiv
  > div
  > p {
  width: 100%;
  // overflow: hidden;
  // white-space: nowrap;
  // text-overflow: ellipsis;
  line-height: 1.5;
  line-break: auto;
}
.feedbackRightSection
  .feedbackRightSectionUl
  .feedbackRightSectionLi
  .custFeedback
  .feedDiv
  > h6 {
  line-height: 1.5rem !important;
}

.feedbackRightHead ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.feedbackRightHead ul li + li {
  margin-top: 8px;
}

// .feedbackRightHead ul li {
//   width: 100%;
//   // align-items: center;
//   // display: flex;
//   // justify-content: space-between;
// }

.feedbackRightHead ul li > .DominosFeedback {
  width: 250px;
}

.DominosFeedback > div > h4 > span > .redeemedColor {
  color: #2bbc91;
}

.feedbackRightHead ul li:last-child {
  min-width: calc(100% - 80%);
  // text-align: center;
  // display: flex;
  justify-content: center;
  margin-top: 0;
}

.feedbackRightHead ul li:last-child div h4 {
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: #ed1c24;
}

.feedbackRightHead ul li h3 {
  //   margin: 0 0 5px 0;
  //   padding: 0;
  //   line-height: 25px;
  //   font-size: var(--font30);
  //   color: var(--darkColor);
  //   font-weight: var(--weight700);
  margin: 0 0 5px 0;
  padding: 0;
  line-height: 25px;
  font-size: var(--font30);
  color: var(--darkColor);
  font-weight: var(--weight700);
  display: -webkit-box;
  max-width: 50%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.feedbackRightHead ul li p {
  margin-bottom: 10px;
  line-height: 26px;
}

.feedbackRightHead ul li h4 {
  // margin: 0;
  // padding: 0;
  // line-height: 26px;
  // font-size: var(--font14);
  // color: var(--greyColor);
  // font-weight: var(--weight500);
  // display: inline-block;
  // align-items: center;
  // gap: 5px;
  // width: 90%;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;

  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin: 0;
  padding: 0;
  line-height: 26px;
  font-size: var(--font14);
  color: var(--greyColor);
  font-weight: var(--weight500);
  display: -webkit-box;
  align-items: center;
  gap: 5px;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  line-break: auto;
}

.feedbackRightHead ul li h4 span em {
  display: inline-flex;
  align-items: center;
}

.feedbackRightHead ul li h4 span {
  color: var(--darkColor);
  font-weight: var(--weight700);
}

.feedbackRightHead ul li > span {
  margin-left: auto;
  font-size: var(--font14);
  font-weight: var(--weight500);
  color: var(--greyColor);
  min-width: 140px;
  text-align: left;
  display: flex;
  align-items: center;
}

.feedbackRightHead ul li > span > strong {
  font-weight: var(--weight700);
  color: var(--darkColor);
  margin: 0 5px;
}

.feedbackRightBody {
  padding: 16px;
}

.ratedProduct .staff-img {
  margin-right: 10px;
}

// ======new css==

.feedBackWpr .custFeedback {
  justify-content: space-between;
}

.feedBackWpr .nav-tabs {
  background-color: rgba(22, 70, 101, 0.08);
  border-radius: 12px;
  border-bottom: 0;
}

.feedBackWpr .nav-tabs .nav-item {
  width: 33.33%;
}

.feedBackWpr .tab-content {
  margin-top: 25px;
}

.feedbackFlex {
  width: 100%;
  display: inherit;
}

.feedbackFlex ul {
  justify-content: space-between;
}

.feedbackFlex ul li {
  width: 80%;
  text-align: left;
  font-size: 14px;
  color: #909090;
  display: flex;
}

.feedbackFlex ul li > div > p {
  display: flex;
  align-items: center;
}

.feedbackFlex ul li > div > p > em {
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-bottom: 1px;
}

.feedbackFlex ul li > div {
  width: 50%;
}

.feedbackFlex ul li:last-child {
  text-align: center;
  margin-bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 20%;
}

.feedbackFlex ul li:last-child p {
  margin-bottom: 0;
  font-weight: 700;
  margin-right: 30px;
  font-size: 14px;
}

.feedbackFlex ul li p strong {
  color: var(--darkColor);
  font-size: var(--font16);
  font-weight: 700;
  margin-left: 5px;
}

.feedBackWpr .feedbackRight .brandAccordion {
  padding: 20px 20px 0 20px;
}

.feedBackWpr .feedbackRight .brandAccordion .card-header {
  margin-bottom: 0px;
}

.feedBackWpr .feedbackRight .brandAccordion.accordion > .feed-card {
  border: 0;
  border: 1px solid var(--bdrColor);
  background: transparent;
  border-radius: var(--Radius12);
  margin: 0;
}

.feedBackWpr .feedbackRight .brandAccordion.accordion > .feed-card.active {
  border: 1px solid var(--bdrColor);
  border-radius: var(--Radius12);
  margin: 5px 0px 5px 0px;
}

.feedBackWpr
  .feedbackRight
  .brandAccordion.accordion
  > .feed-card
  > .card-header {
  padding: 1rem;
  margin: 0;
  border: 0;
  background: transparent;
}

.feedBackWpr
  .feedbackRight
  .brandAccordion.accordion
  > .card
  > .card-header
  .btn {
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;
  padding: 0;
  width: 100%;
}

.feedbackRight .brandAccordion .border-bottom {
  border-bottom: 1px solid var(--bdrColor) !important;
}

.feedBackWpr .custFeedback p {
  line-height: 22px;
}

.feedBackWpr .feedbackRightHead ul li p {
  margin: 10px 0;
  line-height: 1;
  color: #909090;
}

.feedBackWpr .feedbackRightHead ul li p strong {
  color: #292d32;
}

// .feedBackWpr .feedbackRightHead ul li h3 {
//   margin-bottom: 0;
// }

.feedFilter ul li {
  width: 20% !important;
}

// ======new css==

.feedBackWpr .nav-tabs {
  background-color: rgba(22, 70, 101, 0.08);;
  border-radius: 12px;
  border-bottom: 0;
}

.feedBackWpr .nav-tabs .nav-item {
  width: 33.33%;
}

.feedBackWpr .tab-content {
  margin-top: 25px;
}

// .feedbackFlex ul li:last-child {
//   text-align: center;
//   margin-bottom: 0;
//   display: flex;
//   justify-content: end;
//   align-items: center;
// }
// .feedbackFlex ul li:last-child p {
//   margin-bottom: 0;
//   font-weight: 700;
//   margin-right: 30px;
//   font-size: 14px;
// }

// .feedbackFlex ul li p strong {
//   color: var(--darkColor);
//   font-size: var(--font16);
//   font-weight: 700;
//   margin-left: 5px;
// }

.feedBackWpr .feedbackRight .brandAccordion {
  padding: 20px 20px 0 20px;
  overflow-y: scroll;
  height: 540px;
}

.feedBackWpr .feedbackRight .brandAccordion .card-header {
  margin-bottom: 0px;
}

.feedBackWpr .feedbackRight .brandAccordion.accordion > .feed-card {
  border: 0;
  border: 1px solid var(--bdrColor);
  background: transparent;
  border-radius: var(--Radius12);
  margin: 0;
}

.feedBackWpr .feedbackRight .brandAccordion.accordion > .feed-card.active {
  border: 1px solid var(--bdrColor);
  border-radius: var(--Radius12);
  margin: 5px 0px 5px 0px;
}

.feedBackWpr
  .feedbackRight
  .brandAccordion.accordion
  > .feed-card
  > .card-header {
  padding: 1rem;
  margin: 0;
  border: 0;
  background: transparent;
}

.feedBackWpr
  .feedbackRight
  .brandAccordion.accordion
  > .card
  > .card-header
  .btn {
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;
  padding: 0;
  width: 100%;
}

.feedbackRight .brandAccordion .border-bottom {
  border-bottom: 1px solid var(--bdrColor) !important;
}

.feedBackWpr .custFeedback p {
  line-height: 22px;
}

.feedBackWpr .feedbackRightHead ul li p {
  margin: 10px 0;
  line-height: 1;
  color: #909090;
  display: flex;
  align-items: center;
  gap: 5px;
}

.feedBackWpr .feedbackRightHead ul li p em {
  display: flex;
  align-items: center;
}

.feedBackWpr .feedbackRightHead ul li p strong {
  color: #292d32;
}

.feedBackWpr .feedbackRightHead ul li h3 {
  margin-bottom: 0;
  width: 100%;
  max-width: 50%;
  line-break: auto;
  display: -webkit-box;
  max-width: 100%;
  font-size: 14px;
  line-height: 1.5;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.feedFilter ul li {
  width: 20% !important;
}

.modal-lg .feedbackRight,
.printModal.feedbackRight {
  width: 100%;
}

.printModal {
  border: 1px solid var(--bdrColor);
  border-radius: 6px;
}

.printModal .feedbackRightSectionUl > span:last-child li {
  border: none;
}

.printModal .feedbackRightSectionUl > span:last-child li p {
  margin-bottom: 0px;
}

.modal-lg .feedbackRightSectionUl span:last-child .feedbackRightSectionLi {
  border-bottom: 0px;
}

.modal-lg .feedbackRightSectionUl span span:last-child .feedbackRightSectionLi {
  border-bottom: 1px solid #a9d0f6;
}

.modal-lg .sendBtn {
  display: flex;
  justify-content: inherit;
  align-items: center;
}

.share-to-div {
  position: relative;
}

.share-to-div .share-to-span {
  position: absolute;
  top: 50%;
  left: 10px;
  font-weight: 700;
  transform: translateY(-50%);
}

.share-to-div .share-to-input {
  padding-left: 35px;
}

.share-to-div .share-to-input2 {
  padding-left: 70px;
}

.check-border-color {
  border-color: #56e2b8;
}

.check-border {
  padding: 10px 0px;
  border-bottom: 1px solid #56e2b8;
}

// ====newCalender==css

.modalCalender {
  width: 100%;
}

.modalCalender div.react-datepicker {
  border-color: var(--bdrColor);
  border: 0px;
  // width: 100%;
}

.modalCalender div.react-datepicker__header {
  background: transparent;
  border-bottom: 1px solid var(--bdrColor);
  margin: 0 25px 0 25px;
}
.modalCalender .react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
  padding: 0 25px 0 25px;
}
.modalCalender .react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #909090;
  display: inline-block;
  // width: 50px;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.modalCalender .react-datepicker__week .react-datepicker__day {
  // width: 44px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  border-radius: 10px;
  margin: 5px;
}

.modal-body .SelectExport {
  padding: 0;
  margin: 32px 0 25px 0;
}

.modal-body .SelectExport h3 {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  color: #101820;
}

.FileRadioWpr .form-check-input {
  margin-left: 0 !important;
}

.FileRadioWpr .form-check-label {
  margin-left: 10px;
}

.modalCalender select.form-select.monthsSelect {
  width: 270px;
}

.modalCalender select.form-select.yearSelect {
  width: auto;
}

.react-datepicker__day--range-end,
.calendar-box .react-datepicker__day--in-range.react-datepicker__day--range-end,
.modalCalender
  .react-datepicker__day--in-range.react-datepicker__day--range-end {
  background-color: var(--primary) !important;
  color: #fff;
  border-radius: 0 10px 10px 0 !important;
}

.react-datepicker__day--selecting-range-start,
.calendar-box
  .react-datepicker__day--in-range.react-datepicker__day--range-start,
.modalCalender
  .react-datepicker__day--in-range.react-datepicker__day--range-start {
  background-color: var(--primary) !important;
  color: #fff;
  border-radius: 10px 0 0 10px !important;
}

.modalCalender .react-datepicker__week .react-datepicker__day {
  // width: 55px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  border-radius: 10px;
}

.calendar-box .react-datepicker__week .react-datepicker__day {
  // width: 45px;
  // width: 80px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  border-radius: 10px;
}

.cus-dropdown.dropdown button {
  padding: 20px 0;
}

.modalBorderBottom {
  position: relative;
}

.modalBorderBottom::after {
  content: "";
  width: 50%;
  right: 0;
  bottom: -10px;
  height: 1px;
  background-color: var(--bdrColor);
  position: absolute;
}

.modalBorderBottom:last-child::after {
  display: none;
}
