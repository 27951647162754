body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mobileFilterModal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: rgb(0,0,0, 0.5);
}

.showMobileModal {
  display: flex;
}
.hideMobileModal {
  display: none;
}

.applyButton {
  background-color: var(--primary);
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  box-shadow: none;
  outline: none;
  margin-top: auto;
  margin-bottom: 50px;
  max-width: 80vw;
  min-width: 50vw;
}

.mobileFilterContainer {
  width: 100%;
  height: 80vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 30px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: auto;
}

.mobileViewDropdown {
  width: 100% !important;
}
.mobileFilterHeader {
  width: 100% !important;
  height: auto;
  padding: .5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobileFilterHeader h4 {
  margin: 0px !important;
  padding: 0px !important;
  flex: 1;
  display: flex;
  justify-content: center;

}
.mobileFilterHeader button {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  outline: none;
  background-color: transparent;
  justify-self: flex-end;
  padding: 2px 5px;
  width: auto;
  height: auto;
}
.closeButton {
  border: none;
  outline: none;
  background-color: transparent;
  justify-self: flex-end;
  margin-right: 0;
}

@media only screen and (max-width: 600px) {
  .FilterBox {
    background-color: #fff;
    padding: 20px;
    width: 100%;
  }
  .FilterBox ul {
    width: 100vw !important;
    display: flex;
    flex-direction: column;
  }
  .FilterBox ul li {
    width: 100vw !important;
  }
  .FilterBox ul li .ellipsis {
    width: 100% !important;
  }
}