.brandRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
  padding: 0 1rem;
  border-bottom: 1px solid rgba(43, 188, 145, 0.25);
}

.brandInfo {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #292d32;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
}

.brandLogo {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: lightgray;
  overflow: hidden;
}

.brandLogo img {
  width: 40px;
  height: 40px;
}

.brandAction {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.iconBtn {
  background-color: white;
  border: 0;
}

.iconBtn svg {
  width: 24px;
  height: 24px;
}
