* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Cairo";
}
a:hover {
  color: #10344c;
}
a {
  transition: ease-in-out 0.5s;
  text-decoration: none;
}
input:focus-visible {
  outline: 0;
}
.flex {
  display: flex;
  flex-wrap: wrap;
}

.login-body {
  height: 100vh;
  background: #ffffff;
}
.login-inner {
  max-width: 400px;
  margin: 0 auto;
  background: rgba(43, 188, 145, 0.1);;
  border-radius: 10px;
  padding: 35px 30px;
  border: 2px solid rgba(43, 188, 145, 0.25);
  box-shadow: 1px 1px 4px 2px rgba(169, 208, 246, 0.25);
}
.login-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-inner h4 {
  font-size: 26px;
  font-family: "Cairo";
  color: var(--primary);
  margin: 0;
  margin-bottom: 20px;
}
.frm-grp {
  margin-bottom: 20px;
}
.inputPassword {
  padding: 4px 40px 4px 10px !important;
}
.input-cstm {
  background: #fff;
  border: 0px solid #777777;
  border-radius: 5px;
  min-height: 52px;
  width: 100%;
  padding: 0 40px 0 10px;
  font-family: "Cairo";
  font-size: 14px;
  color: #000;
}
.span-eye {
  position: relative;
  display: block;
}
.span-eye i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  color: #777777;
}
.with-error {
  right: 30px !important;
}
.check-login {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.custom-checkbox {
  position: relative;
  display: flex;
  align-items: center;
}
.custom-checkbox input {
  opacity: 0;
  height: 16px;
  width: 16px;
  display: none;
}
.custom-checkbox label {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #fff;
  padding-left: 24px;
}
.chb-3 + label::before {
  position: absolute;
  left: 0;
  top: 2px;
  border: 1px solid #fff;
  content: " ";
  width: 16px;
  height: 16px;
  transition: ease-in-out 0.2s;
  border-radius: 2px;
}
.chb-3:checked + label::before {
  transform: rotate(40deg);
  border-top-color: transparent;
  border-left-color: transparent;
  top: 2px;
  left: 5px;
  width: 7px;
  height: 14px;
}
.a-forgot {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  color: var(--greenText);
  text-decoration: none;
}
.btn-submit button {
  background: #10344c;
  border-radius: 5px;
  font-family: "Cairo";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  min-height: 48px;
  transition: ease-in-out 0.5s;
}
.btn-submit button:hover {
  background: #1a4866;
}
.logo-login {
  text-align: center;
  margin-bottom: 50px;
  height: 50px;
}
.login-main-wrapper {
  padding: 20px 0;
}

.forgot-wrapper .login-inner p {
  font-size: 13px;
  margin: 0;
  color: rgb(19, 17, 17);
  margin-bottom: 15px;
  font-family: "Cairo";
}
.forgot-wrapper .login-inner h4 {
  margin-bottom: 10px;
}

.set-password-link:hover {
  color: white;
}
.login-buuton {
  text-align: center;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-buuton button {
  width: 250px;
}
.laungesTrustree {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  color: black;
}
.laungesTrustree > a {
  color: var(--greenText);
}
.laungesTrustree strong {
  padding-right: 10px;
}
.backLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #fff;
  margin-top: 15px;
  cursor: pointer;
}
.backLogin:hover {
  color: #1a4866;
}

.mobileFilterButton {
  width: 53.6px;
  height: 53.6px;
  border-radius: 12px;
  border: 1px solid #2BBC91;
  background-color: transparent;
  margin-left: 10px;
}
/* media start here */
@media (max-width: 767px) {
}
