
.btn_wpr{margin: 50px;}
.btn_wpr_inner {padding-bottom: 20px;}
.PrimaryColor{color:#fff;font-size: 16px; transition: all 0.5s;  background: var(--primary);box-shadow: 0px 1px 2px rgba(169, 208, 246, 0.25);border-radius: 12px;outline: none;border: none;}
.PrimaryColor:hover{background: #56e2b8;color:#fff;}
.save_btn,.cancel{padding: 15px 17px;min-width: 120px;}

/* ===cancelb btn_Color=== */
.cancel_btn_Color{font-size: 16px; color: #292D32; background: #FFFFFF;border: 1px solid var(--bdrColor);box-shadow: 0px 1px 2px rgba(169, 208, 246, 0.25);border-radius: 12px;}
.cancel_btn_Color:hover{background: linear-gradient(0deg, rgba(41, 45, 50, 0.1), rgba(41, 45, 50, 0.1)), #FFFFFF;}


/* ======resolved btn_Color====== */

.resolved_btn_Color{font-size: 16px;color:var(--primary);border: 1px solid var(--primary);border-radius: 12px;}
.resolved_btn_Color:hover{background-color: var(--primary);color: #fff;}
.resolved,.disable_resolved{min-width: 120px; padding: 6px 0px; }

/* ======Button_Disabled==== */
.Button_Disabled{color:#fff; outline:none;border:none;background: rgba(22, 70, 101, 0.25);box-shadow: 0px 1px 2px rgba(169, 208, 246, 0.25);border-radius: 12px;}
.disable,.disable_cancel{padding: 15px 17px; min-width: 120px;}

/* ======Button_Disabled=cancle=== */
.Disabled_cancel_btn{ color: #909090;background: #FFFFFF;border: 1px solid rgba(144, 144, 144, 0.75);box-shadow: 0px 1px 2px rgba(169, 208, 246, 0.25);border-radius: 12px;}

/* ======disable_resolved=cancle=== */

.Disabled_resolved_btn{outline: none;border: none;color:#909090;border:1px solid #909090;border-radius: 12px;}