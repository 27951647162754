.container {
  color: #292d32;
}

.container h1 {
  font-size: 28px;
  font-weight: 700;
  line-height: normal;
}

.section {
  margin-top: 2rem;
}

.section h2 {
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
}
