.iconBtn {
  background-color: white;
  border: 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #2bbc91;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px; /* 125% */
}

.iconBtn svg {
  width: 24px;
  height: 24px;
  fill: #2bbc91;
  stroke-width: 0.5px;
}
