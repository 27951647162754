@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.brand_report {
    padding: 25px;
    margin: 50px auto;
    max-width: 700px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.content_head {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.logo__chickinWorx {
    width: 150px;
}

.logo__chickinWorx>img {
    width: 100%;
    border-radius: 5px;
}

.brand_name {
    font-family: 'Poppins', sans-serif;
}

.experience_type {
    font-family: "Cairo";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    text-align: right;
    color: #909090;
    margin-bottom: 15px;
}

.summary_table {
    margin-top: 6px;
    width: "100%";
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(43, 188, 145, 0.25);
}

.summary_title {
    width: 50%;
    text-align: start;
    padding: 10px;
    align-items: center;
    display: flex;
}

.summary_name {
    width: 50%;
    text-align: start;
    padding: 10px;
    align-items: center;
    display: flex;
}

.summary_name>img {
    width: 14px;
    margin-left: 5px;
}

.summary_name>span {
    margin-left: 5px;
    font-family: "Cairo";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    text-align: right;
    color: #909090;
}

.detail_name {
    margin-left: 8px;
    font-family: "Cairo";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    text-align: right;
    color: #292D32;
}

.view_button {
    font-family: "Cairo";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 30px;
    text-align: right;
    color: #2BBC91;
    text-decoration: underline;
}

.increment_status {
    display: flex;
    background-color: #e5ffe8;
    color: #3fe087;
    width: 60px !important;
    border-radius: 20px;
    align-items: center;
    padding: 5px;
    justify-content: center;
    margin-left: 5px;
}

.decrement_status {
    display: flex;
    background-color: #ffe5e5;
    color: #ed1c24;
    width: 60px !important;
    border-radius: 20px;
    align-items: center;
    padding: 5px;
    justify-content: center;
    margin-left: 5px;

}

.increment_count {
    margin-left: 6px;
    font-size: 14px;
    line-height: 18.60px;
}

.decrement_count {
    margin-left: 4px;
    font-size: 14px;
    line-height: 18.60px;
}

.decrement_status .increment_status>img {
    width: 13px;
}

@media (max-width: 450px) {
    .brand_report {
        padding: 5px;
        margin: 50px auto;
        max-width: 450px;
        border-radius: 0px;
        border: 0px solid rgba(0, 0, 0, 0.1);
    }

    .summary_table {
        margin-top: 6px;
        width: 100%;
        display: flex;
        border-bottom: 1px solid rgba(43, 188, 145, 0.25);
    }

    .summary_title {
        width: 47%;
        text-align: start;
        padding: 10px;
        align-items: center;
        display: flex;
    }

    .summary_name {
        width: 53%;
        text-align: start;
        padding: 10px;
        align-items: center;
        display: flex;
    }
}