.arabic {

  .BackArrrow_Btn {
    .navBack {
      img {
        transform: rotateY(180deg);
      }
    }

    .navLogo {
      img {
        margin-left: auto;
        margin-right: 80px;
      }
    }
  }

  .HomeReward {
    .borderDashed {
      .SurvetBox {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 50px;
        }

        p {
          text-align: center;
          font-size: 10px;
        }
      }

      button {
        margin: 40px 0 auto;
      }
    }

    ul {
      margin-top: 50px;

      li {
        text-align: center;
        color: #909090;

        strong {
          font-size: 10px;
          margin-bottom: 5px;
        }

        p {
          font-size: 8px;
        }
      }
    }
  }

  .paddingApp {
    .HomeReward {
      .RewardSec {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        padding: 0 24px;
        align-items: center;
        text-align: center;
        gap: 8px;

        .btn {
          height: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 10px;
          width: 100%;
        }
      }

      .borderDashed {

        .SurvetBox {
          text-align: center;
          justify-content: center;
          display: flex;
          border-radius: var(--radius12);

          div {
            img {
              margin: 0 auto;
            }
          }
        }

        p {
          margin-bottom: 30px;
          font-size: 10px;
          line-height: 15px;
          color: var(--darkColor);
          font-weight: var(--Weight600);
          margin-bottom: 0;
          margin-top: 0;
        }
      }

      ul {
        margin: 0;
        position: sticky;
        top: 100%;
        padding: 0 0 24px;

        li {
          padding: 0 0 8px 0;
          font-size: 14px;
          font-weight: var(--Weight600);
          color: #909090;
        }
      }
    }
  }

  .paddingApp {
    .folloItem {
      text-align: center;
      padding: 15px 0;
      margin: 0;
      font-size: 10px;
      font-weight: var(--Weight600);
      color: var(--darkColor);
    }

    .StarRatting {
      padding: 0 8px;

      h5 {
        padding-bottom: 1px;
        line-height: 15px;
        margin: 0;
        font-size: 10px;
        font-weight: var(--Weight600);
        color: var(--darkColor);
      }

      .Nestbtn {
        margin-top: 10px;
        width: 100% !important;

        .category {
          margin-bottom: 5px;

          .star-grad {
            width: 14px;
          }

          .star-container {
            svg {
              width: 15px !important;
              height: 15px !important;
              border-radius: 5px !important;
            }
          }
        }

        button {
          margin: 30px 0 0 0;
        }
      }
    }

    div {
      .star-svg {
        margin-right: 15px;
        display: initial;
      }
    }


    .ProductTabs {
      .sandos-box {
        // height: 410px;
        // overflow-y: scroll;
        // overflow-x: hidden;
        align-items: center;

        &::-webkit-scrollbar {
          width: 3px;
        }


        .sandos-flex {
          .Box {
            img {
              width: 40px;
              height: 40px;
            }
          }
        }

        ul {
          li {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            align-items: center;

            .BoxRate {
              div {
                width: 60px;
                height: 60px;


                .Box {
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  img {
                    width: 60px;
                    height: 60px;
                    border-radius: 5px;
                    margin-bottom: 4px;
                  }
                }

                p {
                  font-size: 10px;
                  margin-bottom: 0;
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }

    .SocialSection {
      padding: 0 10px;

      .multiButton {
        width: 100%;
      }
    }
  }


  // .ProductTabs {
  //   width: 100%;
  //   padding: 0 10px;

  //   .nav-tabs {
  //     display: flex;
  //     border: 0 !important;
  //     overflow-x: scroll;
  //     width: 100%;
  //     flex-wrap: initial;

  //     // padding: 0 10px;
  //     &::-webkit-scrollbar {
  //       height: 2px;
  //     }

  //     /* Track */
  //     &::-webkit-scrollbar-track {
  //       background: #f1f1f1;
  //     }

  //     /* Handle */
  //     &::-webkit-scrollbar-thumb {
  //       background: #f1f1f1;
  //     }

  //     /* Handle on hover */
  //     &::-webkit-scrollbar-thumb:hover {
  //       background: #555;
  //     }

  //     .nav-item {
  //       padding: 0 2px;
  //       height: 20px;
  //       white-space: nowrap;

  //       .nav-link {
  //         padding: 4px;
  //         font-size: 10px;
  //         background: transparent;
  //         color: #909090;
  //         border-radius: 0;
  //       }
  //     }

  //     .nav-link.active:hover,
  //     .nav-link.active {
  //       color: #000000 !important;
  //       border-bottom: 1px solid #ed1c24;
  //     }
  //   }

  //   .tab-content {
  //     .sandos-box {
  //       position: relative;

  //       .menu_Flex {
  //         display: flex;
  //         align-items: center;
  //         width: 100%;

  //         .sandos-flex {
  //           width: 20%;
  //           .Box {
  //             img {
  //               width: 40px;
  //             }
  //           }
  //         }
  //       }

  //       .Text_box {
  //         margin-left: auto;
  //         margin-right: 10px;
  //         h4 {

  //           right: auto;
  //           left: 10px;
  //         }

  //         .sandosBox {
  //           display: block;
  //           width: 65%;

  //           h6 {
  //             margin-bottom: 2px;
  //             font-weight: 500;
  //             font-size: 10px;
  //             color: #292d32;
  //             text-overflow: ellipsis;
  //             overflow: hidden;
  //             width: 90%;
  //           }

  //           .bigFont {
  //             font-weight: normal;
  //             width: 100%;
  //             overflow: hidden;
  //             text-overflow: ellipsis;
  //             white-space: nowrap;
  //             margin-bottom: 0;
  //           }
  //         }
  //       }

  //     }
  //   }
  // }


  .info_Section {
    .information_Wpr {
      h6 {
        font-size: 10px;
        text-align: center;
        font-weight: 700;
      }

      p {
        font-size: 10px;
        text-align: center;
      }

      .searchIcon {
        .checkbox_Sec {
          margin-top: 10px;

          li {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            input[type="checkbox"] {
              width: 10px;
            }

            label {
              font-size: 10px;
              margin-left: auto;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }


  .intl-tel-input.allow-dropdown input,
  .intl-tel-input.allow-dropdown input[type=text],
  .intl-tel-input.allow-dropdown input[type=tel] {
    padding-right: 52px;
    padding-left: 6px;
    margin-left: 0;
  }
}