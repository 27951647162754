.brand_name_report {
    font-size: 28px;
    font-family: "Cairo";
    line-height: 52px;
}

.multiBox {
    border: 1px solid var(--bdrColor);
    border-radius: var(--Radius6);
    padding: 10px 18px 13px 17px;
    position: relative;
    min-height: 90px;
}

.multiBox h3 {
    margin: 0;
    padding: 0;
    display: block;
    font-size: var(--font14);
    font-weight: var(--weight400);
    color: var(--greyColor);
    line-height: 26px;
}

.multiBox p {
    margin: 0;
    padding: 0;
    display: block;
    font-size: var(--font20);
    font-weight: var(--weight600);
    color: var(--darkColor);
    line-height: 36px;
}

.decrement_status{
    display: flex;
    background-color: #ffe5e5;
    color: #ed1c24;
    width: 60px !important;
    border-radius: 20px;
    align-items: center;
    padding: 5px;
    justify-content: center;
    margin-left: 5px;
}

.increment_count{
    margin-left: 6px;
    font-size: 14px;
    line-height: 18.60px;
}

.decrement_count{
    margin-left: 4px;
    font-size: 14px;
    line-height: 18.60px;
}