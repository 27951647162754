.numberInput {
  background-color: white !important;
  color: black !important;
  width: 4.5rem;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 3px 12px !important;
  border-radius: 12px !important;
  border: 1px solid rgba(43, 188, 145, 0.25);
  background: white;
  box-shadow: 0px 1px 2px 0px rgba(169, 208, 246, 0.25);
}
.container {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(43, 188, 145, 0.25);
}
.targetContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}

.iconBtn {
  padding: 3px 12px !important;
  border: 0;
  background: var(--white, #fff);
}

.iconBtn:disabled {
  opacity: 0.5;
  background-color: lightgray;
  border: 0;
  cursor: not-allowed;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.valueInfo {
  display: flex;
  gap: 1rem;
}

.infoCurrent {
  display: flex;
  padding: 0.5rem;
  min-width: 16rem;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  font-weight: 600;
  line-height: 20px; /* 125% */
  background: #f0efef;
  color: #636363;
}

.infoTarget {
  display: flex;
  padding: 0.5rem;
  min-width: 16rem;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  color: #636363;
  font-weight: 600;
  line-height: 20px; /* 125% */
  background: rgba(43, 188, 145, 0.25);
  color: #2bbc91;
}
