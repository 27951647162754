.container {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.container > button {
  border-radius: 12px;
  border: 1px dashed #2bbc91;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 2rem 4rem;
  color: rgba(41, 45, 50, 0.5);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.spinner {
  margin: 0;
  width: 2rem;
  height: 2rem;
  color: #2bbc91;
}
